export const meat = `Lamb Shanks
Pork Ribs
Beef Ribs
Kielbasa- The Polish Sausage
Texas Hot Links
Italian Sausage
Bratwurst
Veal
Venison
Ground Beef
Brisket
Bacon
Skirt Steak
Pork Chops
Pulled Pork
Pulled Turkey
Chicken Breasts
Chicken Legs
Chicken Wings
Prime Rib
Sirloin
T-Bone
New York Strip
Tri Tips
Pork Shoulders
Beef Wellington
Honey Ham
Peppered Ham
Porterhouse Steak
Beef Roast
Cow Tongue
Gator Meat
Ostrich Meat
Filet Mignon
Beef Shank
Rib Eye
Chorizo
Carne Asada
Barbacoa
Birria
Squirrel
Camel
Emu
Kangaroo
Bison
Rattlesnake
Frog Legs`;
