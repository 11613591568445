import { styled } from '@mui/material';
import { FC } from 'react';

const StyledSettings = styled('div')`
	margin: 20px;
`;

export const SettingsLayout: FC = props => (
	<StyledSettings>{props.children}</StyledSettings>
);
