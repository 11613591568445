import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() =>
	import('./Today').then(({ Today }) => ({
		default: Today,
	}))
);

export function Today() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
