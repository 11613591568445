import { api, db } from './firebase';
import { toArray } from './util';

const vapidPublicKey =
	'BN4U_0UEU-nIuUF7eA2UuY-FFJ7K9eQtg4MAF2Fc4EoblEmFcg_JpF-ljxzwKFTZwsQzNdUynQGioX8Lr1zwDVo';

export interface Subscription<T = any> {
	type: NotificationType;
	userId: string;
	householdId: string;
	data?: T;
}

export enum NotificationType {
	'thaw' = 'thaw',
	'feedback' = 'feedback',
}

export const notificationTypes = [
	{
		type: NotificationType.thaw,
		label: 'Thaw reminder',
		time: true,
		description:
			'If an upcoming meal has frozen ingredients, Reciplan can notify you the day before to thaw the igredients.',
	},
	{ type: NotificationType.feedback, label: 'Admin feedback' },
];

async function create(value: Pick<Subscription, 'type' | 'data'>): Promise<Subscription> {
	const newValue: Subscription = {
		...value,
		userId: api.userId,
		householdId: api.householdId!,
	};
	const newRef = db.ref(api.db, `subscriptions/${api.userId}/${value.type}`);
	await db.set(newRef, newValue);
	return { ...newValue };
}

async function update(value: Subscription) {
	if (!value.type) {
		return;
	}
	const userRef = db.ref(api.db, `subscriptions/${api.userId}/${value.type}`);
	await db.update(userRef, value);
}

async function get(): Promise<Subscription[]> {
	const snapshot = await db.get(db.child(db.ref(api.db), `subscriptions/${api.userId}`));
	if (snapshot.exists()) {
		return toArray(snapshot.val());
	}

	return [];
}

async function remove(value: Subscription | undefined) {
	if (!value?.type) {
		return;
	}

	const dbRef = db.ref(api.db, `subscriptions/${api.userId}/${value.type}`);
	await db.remove(dbRef);
}

async function removeAll() {
	const dbRef = db.ref(api.db, `subscriptions/${api.userId}`);
	await db.remove(dbRef);
}

async function getDevice() {
	const registration = await (process.env.NODE_ENV === 'production'
		? navigator.serviceWorker.ready
		: Promise.reject());
	const subscription = await registration.pushManager.getSubscription();

	if (subscription) {
		return subscription;
	}

	const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
	const newSubscription = await registration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: convertedVapidKey,
	});
	return newSubscription;
}

async function getRegisteredDevice(): Promise<PushSubscription | undefined> {
	const snapshot = await db.get(db.child(db.ref(api.db), `pushDevices/${api.userId}`));
	if (!snapshot.exists()) {
		return undefined;
	}
	return JSON.parse(snapshot.val());
}

async function registerDevice(device: PushSubscription) {
	const newRef = db.ref(api.db, `pushDevices/${api.userId}`);
	await db.set(newRef, JSON.stringify(device));
	return device;
}

async function removeDevice() {
	const newRef = db.ref(api.db, `pushDevices/${api.userId}`);
	await db.set(newRef, null);
}

function urlBase64ToUint8Array(base64String: string) {
	var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	var rawData = window.atob(base64);
	var outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export const NotificationApi = {
	create,
	update,
	get,
	remove,
	removeAll,
	getDevice,
	getRegisteredDevice,
	registerDevice,
	removeDevice,
};
