export const dairy = `Baked Milk
Basundi
Blaand
Booza
Buffalo Curd
Bulgarian Yogurt
Butter
Unsalted Butter
Buttermilk
Buttermilk Koldskal
Cacik
Camel Milk
Chaas
Cheese
Clabber
Cornish Clotted Cream
Condensed Milk
Cottage Cheese
Cream
Cream Cheese
Creme Anglaise
Creme Fraiche D'isigny
Cuajada
Curd
Custard
Dadiah
Dondurma
Dulce De Leche
Doogh
Evaporated Milk
Filmjolk
Feta
Fromage Frais
Frozen Custard
Frozen Yoghurt
Gelato
Goat's Milk
Gomme
Horse Milk
Ice Cream
Ice Milk
Infant Formula
Junket
Kashk Bademjan
Kaymak
Kefir
Khoa
Kulfi
Kumiss
Lassi
Matzoon
Milk
Mitha Dahi
Moose Milk
Mursik
Paneer
Pomazankove Maslo
Powdered Milk
Processed Cheese
Qimiq
Quark
Qatiq
Reindeer Milk
Ryazhenka
Semifreddo
Sheep's Milk Yogurt
Shrikhand
Skyr
Smantana
Smetana
Soft Serve
Sour Cream
Spaghettieis
Strained Yogurt
Surmjolk
Tarhana
Tuttis
Urda
Uunijuusto
Whey
Whey Protein
Whipped Cream
Yak Butter
Yakult
Yogurt
Zincica
American Cheese
Gouda
Havarti
Brie
Parmesan Cheese
Grana Padano
Feta
Ricotta
Mozzarella
Gruyere
Baby Swiss
Pepper Jack
Colby
Colby-Jack
Cheddar
Muenster
Gorgonzola
Bleu d'Auvergne
Provolone
Monterey Jack Cheese
Romano Cheese
Cream Cheese
Velveeta
Swiss Cheese
Manchego
Queso Fresco
Roquefort
Mascarpone
Asiago
Edam
Gloucester Cheese
Goat Cheese
Esrom
Egg
Eggs`;
