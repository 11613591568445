import React from 'react';
import { Checkbox } from './Checkbox';
import { isIframed } from './isIframed';

export function IngredientCheckbox({ ingredient }: { ingredient: string }) {
	const [checked, setChecked] = React.useState(false);

	if (!ingredient.trim()) {
		return null;
	}

	return (
		<div>
			<Checkbox
				checked={checked}
				onChange={() => setChecked(!checked)}
				formSx={{ alignItems: 'flex-start' }}
			>
				<div
					style={{
						fontSize: isIframed() ? '1.5rem' : '1rem',
						marginTop: isIframed() ? '4px' : '10px',
						textDecoration: checked ? 'line-through' : 'unset',
					}}
				>
					{ingredient}
				</div>
			</Checkbox>
		</div>
	);
}
