import React from 'react';

interface Action {
	undo: () => Promise<any>;
	redo: () => Promise<any>;
}

export interface Undo {
	add(...actions: Action[]): void;
	onUndo(): void;
	onRedo(): void;
	canUndo: boolean;
	canRedo: boolean;
}

const UndoContext = React.createContext<Undo>({
	add: () => {},
	onUndo: () => {},
	onRedo: () => {},
	canUndo: false,
	canRedo: false,
});

export const UndoProvider: React.FC = props => {
	const [history, setHistory] = React.useState<Action[][]>([]);
	const [index, setIndex] = React.useState(-1);

	return (
		<UndoContext.Provider
			value={{
				canUndo: index >= 0,
				canRedo: index < history.length - 1,
				add,
				onUndo,
				onRedo,
			}}
		>
			{props.children}
		</UndoContext.Provider>
	);

	function add(...actions: Action[]) {
		const validActions = actions.filter(Boolean);
		if (index === history.length - 1) {
			setHistory([...history, validActions]);
		} else {
			setHistory([...history.slice(0, index + 1), validActions]);
		}
		setIndex(index + 1);
	}

	function onUndo() {
		const actions = history[index];
		actions.forEach(a => a.undo());
		setIndex(index - 1);
	}

	function onRedo() {
		const actions = history[index + 1];
		actions.forEach(a => a.redo());
		setIndex(index + 1);
	}
};

export const useUndo = () => React.useContext(UndoContext);
