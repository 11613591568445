import { useSearchParams } from 'react-router-dom';
import { Button, ButtonGroup, Typography } from '@mui/material';
import { BackendApi } from 'Api';

export function Authorize() {
	const [searchParams] = useSearchParams();

	const client_id = searchParams.get('client_id') || '';
	const redirect_uri = searchParams.get('redirect_uri') || '';

	if (client_id !== '087a1415-2e95-4086-8aeb-79cd1c35df78') {
		return null;
	}

	return (
		<div
			style={{
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<div
				style={{
					maxWidth: '320px',
					width: '100%',
					padding: '0 10px',
					boxSizing: 'border-box',
				}}
			>
				<Typography gutterBottom>Google would like to view your recipes.</Typography>
				<ButtonGroup>
					<Button variant="contained" onClick={authorize}>
						Allow
					</Button>
					<Button onClick={deny}>Deny</Button>
				</ButtonGroup>
			</div>
		</div>
	);

	async function authorize() {
		const redirect = new URL(redirect_uri);
		const code = await BackendApi.createCode(client_id, redirect_uri);
		redirect.searchParams.append('code', code);
		window.location.assign(redirect);
	}

	async function deny() {
		const redirect = new URL(redirect_uri);
		redirect.searchParams.append('error', 'denied');
		window.location.assign(redirect);
	}
}
