import { Switch, styled, Tooltip } from '@mui/material';
import React from 'react';

interface Props {
	label: React.ReactNode;
	checked: boolean;
	onChange(checked: boolean): void;
	tooltip?: string;
	disabled?: boolean;
	children?: React.ReactNode;
	style?: React.CSSProperties;
}

const StyledToggle = styled('div')`
	display: flex;
	align-items: center;
	margin: 8px 0px;
	${props => props.theme.breakpoints.down('sm')} {
		justify-content: space-between;
	}
	${props => props.theme.breakpoints.up('sm')} {
		flex-direction: row-reverse;
		justify-content: flex-end;
	}
`;

export function Toggle(props: Props) {
	return (
		<StyledToggle style={props.style}>
			<div style={{ marginRight: '24px' }}>
				<div>{props.label}</div>
				<div>{props.children}</div>
			</div>
			<Tooltip title={props.tooltip ?? ''}>
				<span>
					<Switch
						checked={props.checked}
						disabled={!!props.disabled}
						onChange={(_, checked) => props.onChange(checked)}
					/>
				</span>
			</Tooltip>
		</StyledToggle>
	);
}
