import {
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	SwipeableDrawer,
	Tooltip,
	IconButton,
} from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBookTwoTone';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import KitchenIcon from '@mui/icons-material/Kitchen';
import React, { useState } from 'react';
import { AboutReciplanModal } from './AboutReciplanModal';
import { useNavigate, useMatch } from 'react-router-dom';
import { BottomAppBar, device, useIsSmallScreen } from 'Components';

interface Props {
	open: boolean;
	setOpen(open: boolean): void;
}

export function NavigationDrawer(props: Props) {
	const navigate = useNavigate();
	const [showAbout, setShowAbout] = useState(false);

	return (
		<>
			<ResponsiveDrawer {...props}>
				<List>
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/home/mealplan')}>
							<ListItemIcon>
								<CalendarMonthIcon />
							</ListItemIcon>
							<ListItemText primary="Meal Plan" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/home/groceries')}>
							<ListItemIcon>
								<CheckBoxOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Groceries" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/home/recipes')}>
							<ListItemIcon>
								<MenuBookIcon />
							</ListItemIcon>
							<ListItemText primary="Recipes" />
						</ListItemButton>
					</ListItem>
					<ListItem disablePadding>
						<ListItemButton onClick={() => navigate('/home/groceries/pantry')}>
							<ListItemIcon>
								<KitchenIcon />
							</ListItemIcon>
							<ListItemText primary="Pantry" />
						</ListItemButton>
					</ListItem>
				</List>
			</ResponsiveDrawer>
			<AboutReciplanModal open={showAbout} onClose={() => setShowAbout(false)} />
		</>
	);
}

const ResponsiveDrawer: React.FC<Props> = props => {
	const smallScreen = useIsSmallScreen();

	if (!smallScreen) {
		return (
			<Drawer
				variant="permanent"
				sx={{
					width: 250,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: 250, boxSizing: 'border-box' },
				}}
			>
				<Toolbar />
				<Box sx={{ overflow: 'auto' }}>{props.children}</Box>
			</Drawer>
		);
	}

	if (device.isiOS()) {
		return (
			<Drawer anchor="left" open={props.open} onClose={() => props.setOpen(false)}>
				<Toolbar />
				<Box sx={{ width: 250 }} onClick={() => props.setOpen(false)}>
					{props.children}
				</Box>
			</Drawer>
		);
	}

	return (
		<SwipeableDrawer
			anchor="left"
			open={props.open}
			onClose={() => props.setOpen(false)}
			onOpen={() => props.setOpen(true)}
		>
			<Toolbar />
			<Box sx={{ width: 250 }} onClick={() => props.setOpen(false)}>
				{props.children}
			</Box>
		</SwipeableDrawer>
	);
};

export function BottomNav() {
	const smallScreen = useIsSmallScreen();

	if (!smallScreen) {
		return null;
	}

	return (
		<BottomAppBar name="nav" height={52} main>
			<div
				style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}
			>
				<Tooltip title="Groceries">
					<NavIcon icon={CheckBoxOutlinedIcon} url="/home/groceries" />
				</Tooltip>
				<Tooltip title="Mealplan">
					<NavIcon icon={CalendarMonthIcon} url="/home/mealplan" />
				</Tooltip>
				<Tooltip title="Recipes">
					<NavIcon icon={MenuBookIcon} url="/home/recipes" />
				</Tooltip>
				<Tooltip title="Pantry">
					<NavIcon icon={KitchenIcon} url="/home/groceries/pantry" />
				</Tooltip>
			</div>
		</BottomAppBar>
	);
}

const NavIcon = React.forwardRef<any, { url: string; icon: any }>((props, ref) => {
	const navigate = useNavigate();
	const active = useMatch(props.url + '/*');

	return (
		<IconButton onClick={() => navigate(props.url)} ref={ref}>
			<props.icon color={active ? 'primary' : undefined} fontSize="large" />
		</IconButton>
	);
});
