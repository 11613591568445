import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

interface ChildrenProps {
	onClick(event: any): void;
}

interface Props {
	children(props: ChildrenProps): React.ReactNode;
	options?: (MenuItemType | false | undefined)[];
	anchorHorizontal?: 'left' | 'right';
	anchorVertical?: 'top' | 'bottom';
	dense?: boolean;
	onClose?(): void;
}

interface MenuItemType {
	onClick?(event: React.MouseEvent<any>): void;
	children?: React.ReactNode;
	disabled?: boolean;
	hasDivider?: boolean;
}

export function DropdownMenu(props: Props) {
	const [anchorEl, setAnchorEl] = React.useState<Element>();
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchorEl(undefined);
		props.onClose?.();
	};
	const anchorHorizontal = props.anchorHorizontal || 'right';
	const anchorVertical = props.anchorVertical || 'top';
	const options = (props.options?.filter(Boolean) as MenuItemType[]) || [];
	return (
		<React.Fragment>
			{props.children({ onClick: handleClick })}
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				transformOrigin={{
					horizontal: anchorHorizontal,
					vertical: anchorVertical === 'top' ? 'bottom' : 'top',
				}}
				anchorOrigin={{
					horizontal: anchorHorizontal,
					vertical: anchorVertical === 'top' ? 'top' : 'bottom',
				}}
			>
				{options.map(option => {
					return [
						option.hasDivider && <Divider />,
						option.onClick ? (
							<MenuItem
								onClick={option.onClick}
								dense={props.dense}
								disableRipple={props.dense}
							>
								{option.children}
							</MenuItem>
						) : (
							option.children
						),
					];
				})}
			</Menu>
		</React.Fragment>
	);
}
