import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() => import('./Login').then(({ Login }) => ({ default: Login })));

export function Login() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
