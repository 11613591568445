import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert, { AlertColor } from '@mui/material/Alert';

interface Props {
	children: React.ReactNode;
	severity: AlertColor;
	autoHideDuration?: number;
	open?: boolean;
	onClose?(): void;
}

export function Toast(props: Props) {
	return (
		<Snackbar
			open={props.open}
			autoHideDuration={props.autoHideDuration}
			onClose={props.onClose}
			action={
				<IconButton
					size="small"
					aria-label="close"
					color="inherit"
					onClick={props.onClose}
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			<Alert
				variant="filled"
				onClose={props.onClose}
				severity={props.severity}
				sx={{ width: '100%' }}
			>
				{props.children}
			</Alert>
		</Snackbar>
	);
}
