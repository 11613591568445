import { Modal, ModalProps, TextField, Link } from 'Components';
import {
	Button,
	ButtonGroup,
	createTheme,
	ThemeProvider,
	Typography,
} from '@mui/material';
import React from 'react';
import { Footer, Body } from './styled';
import { CameraModule } from './CameraModule';
import { useAppContext } from 'AppContext';
import { reciplanTheme } from 'Components/theme';
import { ModalFooter } from 'Components/Modal';

interface Props extends ModalProps {
	psm: number;
	onDone(value: string): void;
}

export function ParseImageText(props: Props) {
	const { psm, onDone, ...modalProps } = props;
	const { user } = useAppContext();
	const [text, setText] = React.useState<string>();

	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					...reciplanTheme,
					mode: 'dark',
				},
			}),
		[]
	);

	if (!user?.emailVerified) {
		return (
			<Modal {...modalProps}>
				<Typography>
					You'll need to verify your email address to use this feature. Visit your
					<Link to="/settings" onClick={close} style={{ margin: '0 5px' }}>
						settings
					</Link>
					page.
				</Typography>
				<ModalFooter>
					<Button variant="outlined" onClick={close}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			<Modal {...modalProps} Body={Body}>
				{!text && <CameraModule psm={psm} onChange={setText} cancel={close} />}
				{text && (
					<>
						<div style={{ margin: '20px' }}>
							<Typography sx={theme => ({ color: theme.palette.text.primary })}>
								We captured this text from your image. Double-check that the text
								converted accurately. You can edit it before saving.
							</Typography>
							<TextField
								fullWidth
								autoFocus
								multiline
								rows={Math.min(20, text.split('\n').length + 1)}
								value={text}
								onChange={setText}
							/>
						</div>
						<Footer>
							<ButtonGroup>
								<Button onClick={() => save()} variant="contained">
									Save
								</Button>
								<Button
									onClick={() => {
										setText(undefined);
									}}
								>
									Retake
								</Button>
								<Button onClick={close}>Cancel</Button>
							</ButtonGroup>
						</Footer>
					</>
				)}
			</Modal>
		</ThemeProvider>
	);

	function save() {
		onDone(text!);
		close();
	}

	function close() {
		setText('');
		props.onClose();
	}
}
