import React from 'react';
import { Form } from '../Form';
import { MealTime, MealApi, Recipe, RecipeApi, demoRecipeIds, SettingsApi } from 'Api';
import { Modal, ModalProps, ModalFooter } from '../Modal';
import { RecipeTypeahead } from '../RecipeTypeahead';
import { Button, ButtonGroup, FormLabel } from '@mui/material';
import { useAppContext } from 'AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { createLeftovers } from './createLeftovers';
import { DatePicker } from './DatePicker';
import { MealTimeDropdown } from '../MealTimeDropdown';

export interface AddMealProps {
	mealTime?: MealTime | null;
	date?: Date;
	recipe?: Recipe;
	canCreateRecipe?: boolean;
}

export function AddMeal(props: AddMealProps & ModalProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { update, pantry, household, recipes } = useAppContext();
	const {
		mealTime: initialMealTime,
		date: initialDate,
		recipe: initialRecipe,
		canCreateRecipe,
		children,
		...modalProps
	} = props;
	const [date, setDate] = React.useState<Date>(getInitialDate());
	const [recipe, setRecipe] = React.useState<string | Recipe | undefined | null>(
		initialRecipe
	);
	const [mealTime, setMealTime] = React.useState<MealTime | undefined | null>(
		initialMealTime
	);

	return (
		<Modal {...modalProps} modalTitle="Add Meal">
			<Form onSubmit={addMeal}>
				<RecipeTypeahead
					label="Recipe"
					mealTime={mealTime}
					defaultValue={props.recipe}
					onChange={(_, newRecipe) => setRecipe(newRecipe)}
					freeSolo={canCreateRecipe}
				/>
				<FormLabel>Time</FormLabel>
				<div>
					<MealTimeDropdown mealTime={mealTime} setMealTime={setMealTime} />
				</div>
				<DatePicker date={date} onChange={setDate} mealTime={mealTime} />
				<ModalFooter>
					<ButtonGroup>
						<Button type="submit" variant="contained" disabled={!recipe || !date}>
							Add
						</Button>
						<Button type="button" onClick={() => props.onClose()}>
							Cancel
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</Form>
		</Modal>
	);

	function getInitialDate() {
		if (!initialDate || initialDate.hasHappened()) {
			return new Date();
		}
		return initialDate;
	}

	async function addMeal() {
		if (!recipe || !date) {
			return;
		}

		let selectedRecipe = recipe as Recipe;
		if (typeof recipe === 'string') {
			selectedRecipe = { id: '', name: recipe };
		}

		if (typeof recipe !== 'string' && demoRecipeIds.includes(recipe.id)) {
			const newHousehold = SettingsApi.updateHouseholdTutorials(household!, recipe.id);
			selectedRecipe = await RecipeApi.create(recipe);
			update({
				recipes: [...recipes!.filter(r => r.id !== recipe.id), selectedRecipe],
				household: newHousehold,
			});
		}

		const newMeal = await MealApi.create(
			{
				date,
				name: selectedRecipe.name,
				mealTime: mealTime || null,
				recipeId: selectedRecipe.id || null,
			},
			selectedRecipe,
			pantry
		);

		if (selectedRecipe.prepIngredients || selectedRecipe.prepInstructions) {
			await MealApi.create(
				{
					date,
					name: selectedRecipe.name,
					mealTime: mealTime || null,
					recipeId: selectedRecipe.id || null,
					parentMealId: newMeal.id,
					isPrep: true,
				},
				undefined,
				undefined
			);
		}
		await createLeftovers(newMeal.id, household, selectedRecipe, date, mealTime || null);
		props.onClose();

		if (
			typeof recipe !== 'string' &&
			location.pathname.endsWith(recipe.id) &&
			recipe.id !== selectedRecipe.id
		) {
			// handle demo recipe creation
			navigate(`/home/recipes/${selectedRecipe.id}`);
		}
	}
}
