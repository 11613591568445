import { api, db } from './firebase';
import { addId } from './util';

export interface Feedback {
	id: string;
	isGood: boolean | null;
	comment: string;
	pathname: string;
	date: string;
	uid: string;
	userAgent: string;
}

async function create(
	value: Pick<Feedback, 'comment' | 'isGood' | 'pathname'>
): Promise<void> {
	if (!value.comment.trim()) {
		return;
	}

	const feedback: Omit<Feedback, 'id'> = {
		...value,
		comment: value.comment.slice(0, 2000),
		date: new Date().toISOString(),
		uid: api.userId,
		userAgent: window.navigator.userAgent,
	};
	const newRef = db.push(db.ref(api.db, 'feedback'));
	await db.set(newRef, feedback);
}

async function get(): Promise<Feedback[]> {
	const ref = db.query(db.ref(api.db, 'feedback'), db.orderByChild('date'));
	const res = await db.get(ref);

	const results: Feedback[] = [];
	res.forEach(child => {
		const result = child.val();
		results.push(addId(child.key!, result));
	});
	return results.reverse();
}

async function remove(value: Feedback) {
	if (!value.id) {
		return;
	}

	const dbRef = db.ref(api.db, `feedback/${value.id}`);
	await db.remove(dbRef);
}

export const FeedbackApi = { create, remove, get };
