import { useAppContext } from 'AppContext';
import { MealTime, Recipe } from 'Api';
import { TextField, Autocomplete, AutocompleteProps } from '@mui/material';
import React from 'react';

interface Props
	extends Omit<AutocompleteProps<Recipe, false, false, any>, 'renderInput' | 'options'> {
	label?: string;
	mealTime?: MealTime | null;
}

export function RecipeTypeahead(props: Props) {
	const { recipes } = useAppContext();
	const { mealTime, label, onChange } = props;
	const [inputValue, onInputChange] = React.useState<string>();

	return (
		<Autocomplete
			freeSolo={props.freeSolo}
			defaultValue={props.defaultValue}
			value={props.value}
			readOnly={props.readOnly}
			disabled={props.disabled}
			options={getAutocomplete()}
			renderInput={params => (
				<TextField {...params} sx={{ width: '300px' }} label={label} margin="normal" />
			)}
			onChange={onChange}
			onInputChange={(_, value) => {
				if (props.freeSolo && onChange) {
					onChange(_, value, 'createOption');
				}
				onInputChange(value);
			}}
			getOptionLabel={r => (r as Recipe).name}
			isOptionEqualToValue={(o, v) => o.id === v.id}
		/>
	);

	function getAutocomplete() {
		return (
			recipes?.filter(r => inputValue || !mealTime || r.tags?.includes(mealTime)) || []
		);
	}
}
