import React from 'react';
import ReactCrop, { PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview, useDebounceEffect } from './util';

export function ImagePreview(props: {
	image: string;
	onClear: () => void;
	onCrop: (value: string) => void;
}) {
	const imgRef = React.useRef<HTMLImageElement>(null);
	const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
	const [crop, setCrop] = React.useState<PixelCrop>();
	const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef && previewCanvasRef) {
				const croppedImage = canvasPreview(
					imgRef.current!,
					previewCanvasRef.current!,
					completedCrop
				);
				props.onCrop(croppedImage);
			}
		},
		100,
		[completedCrop]
	);

	React.useEffect(() => {
		setTimeout(() => {
			setDefaultCrop();
		}, 500);
	}, []);

	return (
		<div>
			<div style={{ padding: '30px' }}>
				<ReactCrop
					crop={crop}
					onChange={c => setCrop(c)}
					onComplete={setCompletedCrop}
					keepSelection
				>
					<img alt="" ref={imgRef} src={props.image} />
				</ReactCrop>
			</div>
			<canvas ref={previewCanvasRef} style={{ display: 'none' }} />
		</div>
	);

	function setDefaultCrop() {
		if (imgRef.current) {
			setCrop({
				unit: 'px',
				x: 40,
				y: 40,
				width: imgRef.current.width - 80,
				height: imgRef.current.height - 80,
			});
		}
	}
}
