import { MealTime } from './MealApi';
import { Recipe } from './RecipeApi';

export const demoRecipeIds = [
	'demo-recipe-breakfast',
	'demo-recipe-lunch',
	'demo-recipe-dinner',
	'demo-recipe-other',
];

export const demoRecipes: Recipe[] = [
	{
		id: 'demo-recipe-breakfast',
		name: 'Old Fashioned Pancakes',
		activeTime: 20,
		inactiveTime: 0,
		tags: [MealTime.breakfast],
		servings: 8,
		ingredients: `1 ½ cups all-purpose flour
3 ½ teaspoons baking powder
¼ teaspoon salt, or more to taste
1 tablespoon white sugar
1 ¼ cups milk
1 egg
3 tablespoons butter, melted`,
		instructions: `In a large bowl, sift together the flour, baking powder, salt and sugar. Make a well in the center and pour in the milk, egg and melted butter; mix until smooth.
Heat a lightly oiled griddle or frying pan over medium-high heat. Pour or scoop the batter onto the griddle, using approximately 1/4 cup for each pancake. Brown on both sides and serve hot.`,
	},
	{
		id: 'demo-recipe-lunch',
		name: 'Hummus Tomato Sandwich',
		tags: [MealTime.lunch],
		activeTime: 5,
		servings: 2,
		ingredients: `1 cup hummus
2 slices bread
4 red lettuce leaves
1 medium tomato`,
		instructions: `Make an open face sandwich with the above ingredients.`,
	},
	{
		id: 'demo-recipe-dinner',
		name: 'Baked fish on roasted caponata',
		tags: [MealTime.dinner],
		activeTime: 15,
		inactiveTime: 30,
		servings: 2,
		ingredients: `1 small eggplant
1 small red chile
1 small zucchini
1 small red onion
1 cup cherry tomatoes
1/2 cup kalamata olives
2 cloves garlic
2 tablespoons evoo
2 tablespoons apple cider vinegar
sea salt
black pepper (freshly ground)
2 fish fillets
2 tablespoons pine nuts
1/4 cup basil
1/2 lemon`,
		instructions: `Preheat convection oven to 400F
In a large bowl, toss together the eggplant, chile, zucchini, onion, tomatoes, olives, garlic with the oil and vinegar. Season to taste w/ S&P.
Arrange the veggies in a large baking dish and roast for 15 min. Remove from the oven and top with the fish fillets. Cook for another 15 min. In the last 5 min. top with pine nuts to lightly toast. 
Remove from the oven and sprinkle with basil leaves. Plate 1 fillet and half the caponata for tonight's dinner and store the rest in an airtight container in the fridge for tomorrow's lunch. Serve with lemon wedges.`,
	},
	{
		id: 'demo-recipe-other',
		name: 'Apple Pie',
		inactiveTime: 60,
		ingredients: `1 pastry
1/2 cup unsalted butter
3 tablespoons all-purpose flour
1/4 cup water
1/2 cup white sugar
1/2 cup brown sugar (packed)
8 granny smith apples`,
		instructions: `Preheat oven to 425 degrees F (220 degrees C). Melt the butter in a saucepan. Stir in flour to form a paste. Add water, white sugar and brown sugar, and bring to a boil. Reduce temperature and let simmer.
Place the bottom crust in your pan. Fill with apples, mounded slightly. Cover with a lattice work crust. Gently pour the sugar and butter liquid over the crust. Pour slowly so that it does not run off.
Bake 15 minutes in the preheated oven. Reduce the temperature to 350 degrees F (175 degrees C). Continue baking for 35 to 45 minutes, until apples are soft.`,
	},
];
