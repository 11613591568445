export const cooking = `Flour
All-purpose Flour
All Purpose Flour
Bread Flour
Kalamata Olives
Sugar
Brown Sugar
Light Brown Sugar
Dark Brown Sugar
Cocoa Powder
Olive Oil
Evoo
Extra-virgin Olive Oil
Apple Cider Vinegar
Salt
Kosher Salt
Sea Salt
Pine Nuts
Black Pepper
Ground Black Pepper
Vanilla
Vanilla Extract
Pure Vanilla Extract
Honey
Baking Powder
Baking Soda
Chocolate Chips
Pancake Mix
Active Dry Yeast
Granulated Sugar
Powdered Sugar
White Sugar
Rolled Oats
Coconut Oil
Cinnamon
Nutmeg
Dried Apples
Soy Sauce
Toasted Sesame Oil
Bread Crumbs
Breadcrumbs
Paprika
Pastry`;
