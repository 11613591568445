import React from 'react';
import { useAppContext } from 'AppContext';
import { getMealDisplayName, padStart } from 'Components';
import { Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

export const TodayMasthead = React.forwardRef((_, ref) => {
	const { meals } = useAppContext();
	const navigate = useNavigate();
	const mealId = useParams()['*'];

	const mealsToday = meals?.filter(meal => meal.date.isSameDate(new Date()));
	const selectedMeal = mealsToday?.find(m => m.id === mealId);

	return (
		<>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
				<Button
					variant="text"
					color="inherit"
					onClick={() => {
						navigate('/home/recipes');
					}}
				>
					All Recipes
				</Button>
				{mealsToday?.map(meal => (
					<Button
						key={meal.id}
						variant="text"
						color="inherit"
						sx={{
							textDecoration: meal.id === selectedMeal?.id ? 'underline' : 'unset',
						}}
						onClick={() => {
							navigate(`/today/${meal.id}`);
						}}
					>
						{getMealDisplayName(meal)}
					</Button>
				))}
			</div>
			<Clock />
		</>
	);
});

function Clock() {
	const [time, setTime] = React.useState(new Date());
	setTimeout(() => setTime(new Date()), 1000);

	return (
		<Typography fontSize={24} sx={{ position: 'fixed', top: 10, right: 10 }}>
			{`${padStart(time.getHours(), 2)}:${padStart(time.getMinutes(), 2)}`}
		</Typography>
	);
}
