import { Recipe } from 'Api';

export function formatCookTime(recipe: Recipe) {
	const activeTime = recipe.activeTime ?? 0;
	const inactiveTime = recipe.inactiveTime ?? 0;
	const totalTime = activeTime + inactiveTime;
	if (totalTime === 0) {
		return '';
	}
	let text = `active: ${activeTime} min`;
	if (inactiveTime) {
		text += ` (inactive: ${inactiveTime} min)`;
	}
	return text;
}
