import React from 'react';
import { useAppContext } from 'AppContext';
import { UserTutorials, SettingsApi, UserSettings } from 'Api';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

interface Props {
	show: boolean;
	anchorId: string;
	tutorialId: UserTutorials;
	placement: PopperPlacementType;
	message: string;
}

export function Tutorial(props: Props) {
	const { userSettings, update } = useAppContext();
	const show = props.show && !userSettings?.completedTutorials?.[props.tutorialId];
	const [open, setOpen] = React.useState(false);

	setTimeout(() => {
		setOpen(show);
	}, 500);

	return (
		<Popper
			open={open}
			anchorEl={document.getElementById(props.anchorId)}
			placement={props.placement}
			transition
		>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<Box
						onClick={e => e.stopPropagation()}
						sx={{
							border: 1,
							p: 1,
							marginBottom: '6px',
							bgcolor: 'background.paper',
							maxWidth: '300px',
							borderRadius: '4px',
						}}
					>
						{props.message}
						<div style={{ marginTop: '20px' }}>
							<ButtonGroup>
								<Button
									variant="text"
									onClick={() => {
										const newSettings = dismissTutorial(userSettings!, props.tutorialId);
										update({ userSettings: newSettings });
										setOpen(false);
									}}
								>
									Dismiss
								</Button>
							</ButtonGroup>
						</div>
					</Box>
				</Fade>
			)}
		</Popper>
	);
}

export function dismissTutorial(
	userSettings: UserSettings | undefined,
	tutorialId: UserTutorials
) {
	const newSettings: UserSettings = {
		...userSettings,
		completedTutorials: {
			...userSettings?.completedTutorials,
			[tutorialId]: true,
		},
	};
	SettingsApi.updateUserSettings(newSettings);
	return newSettings;
}
