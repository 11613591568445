import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() =>
	import('./Admin').then(({ Admin }) => ({
		default: Admin,
	}))
);

export function Admin() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
