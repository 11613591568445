import { IconButton } from '@mui/material';
import { Popover } from 'Components';
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const LongTooltip: React.FC = props => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<IconButton onClick={handleClick} sx={{ margin: '0px 8px' }}>
				<HelpOutlineIcon />
			</IconButton>
			<Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
				<div style={{ maxWidth: '400px' }}>{props.children}</div>
			</Popover>
		</>
	);
};
