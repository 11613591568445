import { Modal, ModalProps, ModalFooter, ModalBody } from './Modal';
import { Button, ButtonGroup, styled, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface Props extends ModalProps {
	message: ReactNode;
	onContinue(): void;
	onContinueText: string;
}

const Body = styled(ModalBody)`
	min-width: 500px;
`;

export function MessageModal(props: Props) {
	const { message, onContinue, onContinueText, ...modalProps } = props;
	return (
		<Modal {...modalProps} modalTitle="Delete Recipe" Body={Body}>
			<Typography sx={{ mt: 2 }}>{message}</Typography>
			<ModalFooter>
				<ButtonGroup>
					<Button variant="contained" onClick={shouldContinue}>
						{onContinueText}
					</Button>
					<Button onClick={onCancel}>Cancel</Button>
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);

	function shouldContinue() {
		onContinue();
		modalProps.onClose();
	}

	function onCancel() {
		modalProps.onClose();
	}
}
