export const ingredients = {
	Apricots: ['apricots', 'apricot'],
	Artichokes: ['artichokes', 'artichoke'],
	Arugula: ['arugula'],
	Asparagus: ['asparagus', 'asparagu'],
	Avocados: ['avocados', 'avocado'],
	Basil: ['basil'],
	Beets: ['beets', 'beet'],
	'Black-eyed Peas': ['black-eyed peas', 'black-eyed pea'],
	'Blood Oranges': ['blood oranges', 'blood orange'],
	Broccoli: ['broccoli'],
	Carrots: ['carrots', 'carrot'],
	Cauliflower: ['cauliflower'],
	Chard: ['chard'],
	Cherries: ['cherries', 'cherry'],
	Corn: ['corn'],
	Eggplant: ['eggplant'],
	'Fava Beans': ['fava beans', 'fava bean'],
	Fennel: ['fennel'],
	Fiddleheads: ['fiddleheads', 'fiddlehead'],
	Garlic: ['garlic'],
	Figs: ['figs', 'fig'],
	Grapefruits: ['grapefruits', 'grapefruit'],
	'Green Onions': ['green onions', 'green onion'],
	'Yellow Onions': ['yellow onions', 'yellow onion'],
	Bananas: ['bananas', 'banana'],
	Kohlrabi: ['kohlrabi'],
	Kumquats: ['kumquats', 'kumquat'],
	'Medjool Dates': ['medjool dates', 'medjool date'],
	Morels: ['morels', 'morel'],
	Mushrooms: ['mushrooms', 'mushroom'],
	'Navel Oranges': ['navel oranges', 'navel orange'],
	Nectarines: ['nectarines', 'nectarine'],
	Nettles: ['nettles', 'nettle'],
	Lettuce: ['lettuce'],
	Okra: ['okra'],
	Parsley: ['parsley'],
	'Passion Fruit': ['passion fruit'],
	'Pea Beans': ['pea beans', 'pea bean'],
	Peaches: ['peaches', 'peache'],
	Plums: ['plums', 'plum'],
	Potatoes: ['potatoes', 'potato'],
	'Russet Potatoes': ['russet potatoes', 'russet potato'],
	Radish: ['radish'],
	Raspberries: ['raspberries', 'raspberry'],
	Rhubarb: ['rhubarb'],
	Spinach: ['spinach'],
	'Spring Onions': ['spring onions', 'spring onion'],
	Strawberries: ['strawberries', 'strawberry'],
	Tomatoes: ['tomatoes', 'tomato'],
	'Cherry Tomatoes': ['cherry tomatoes', 'cherry tomato'],
	Turnips: ['turnips', 'turnip'],
	Apples: ['apples', 'apple'],
	Blackberries: ['blackberries', 'blackberry'],
	Blueberries: ['blueberries', 'blueberry'],
	Boysenberries: ['boysenberries', 'boysenberry'],
	Cabbage: ['cabbage'],
	Collards: ['collards', 'collard'],
	Eggplants: ['eggplants', 'eggplant'],
	Grapes: ['grapes', 'grape'],
	Kale: ['kale'],
	Lemons: ['lemons', 'lemon'],
	Melons: ['melons', 'melon'],
	Mustard: ['mustard'],
	Peppers: ['peppers', 'pepper'],
	Persimmons: ['persimmons', 'persimmon'],
	Sapote: ['sapote'],
	'Summer Squash': ['summer squash'],
	Tomatillos: ['tomatillos', 'tomatillo'],
	'Valencia Oranges': ['valencia oranges', 'valencia orange'],
	'Asian Pears': ['asian pears', 'asian pear'],
	'Brussels Sprouts': ['brussels sprouts', 'brussels sprout'],
	Carambola: ['carambola'],
	Celery: ['celery'],
	Cherimoyas: ['cherimoyas', 'cherimoya'],
	'Chili Pepper': ['chili pepper'],
	Cranberries: ['cranberries', 'cranberry'],
	Edamame: ['edamame'],
	'Green Beans': ['green beans', 'green bean'],
	Guava: ['guava'],
	Kiwi: ['kiwi'],
	Leeks: ['leeks', 'leek'],
	Lemongrass: ['lemongrass', 'lemongras'],
	Limes: ['limes', 'lime'],
	Onions: ['onions', 'onion'],
	Oranges: ['oranges', 'orange'],
	Parsnips: ['parsnips', 'parsnip'],
	Pears: ['pears', 'pear'],
	Pineapples: ['pineapples', 'pineapple'],
	Pomegranates: ['pomegranates', 'pomegranate'],
	Pumpkins: ['pumpkins', 'pumpkin'],
	Quinces: ['quinces', 'quince'],
	Radishes: ['radishes', 'radishe'],
	Rapini: ['rapini'],
	Rutabaga: ['rutabaga'],
	Salsify: ['salsify'],
	Scallions: ['scallions', 'scallion'],
	Shallots: ['shallots', 'shallot'],
	'Sweet Potatoes': ['sweet potatoes', 'sweet potato'],
	Yams: ['yams', 'yam'],
	'Green Peas': ['green peas', 'green pea'],
	Tangelos: ['tangelos', 'tangelo'],
	Tangerines: ['tangerines', 'tangerine'],
	'Winter Squash': ['winter squash'],
	'Chayote Squash': ['chayote squash'],
	'Collard Greens': ['collard greens', 'collard green'],
	Cucumbers: ['cucumbers', 'cucumber'],
	'English Cucumbers': ['english cucumbers', 'english cucumber'],
	Endive: ['endive'],
	'Green Bell Peppers': ['green bell peppers', 'green bell pepper'],
	Jalapenos: ['jalapenos', 'jalapeno'],
	'Mustard Greens': ['mustard greens', 'mustard green'],
	'Snap Peas': ['snap peas', 'snap pea'],
	'Snow Peas': ['snow peas', 'snow pea'],
	'Swiss Chard': ['swiss chard'],
	Watercress: ['watercress', 'watercres'],
	Zucchini: ['zucchini'],
	Acerola: ['acerola'],
	'Dragon Fruit': ['dragon fruit'],
	Lingonberries: ['lingonberries', 'lingonberry'],
	Radicchio: ['radicchio'],
	'Red Apples': ['red apples', 'red apple'],
	'Red Beets': ['red beets', 'red beet'],
	'Red Cabbage': ['red cabbage'],
	'Red Currants': ['red currants', 'red currant'],
	'Red Grapefruits': ['red grapefruits', 'red grapefruit'],
	'Red Grapes': ['red grapes', 'red grape'],
	'Red Onions': ['red onions', 'red onion'],
	'Red Pears': ['red pears', 'red pear'],
	'Red Peppers': ['red peppers', 'red pepper'],
	'Red Potatoes': ['red potatoes', 'red potato'],
	'Rose Hip': ['rose hip'],
	Watermelon: ['watermelon'],
	'Acorn Squash': ['acorn squash'],
	'Butternut Squash': ['butternut squash'],
	Clementine: ['clementine'],
	'Hubbard Squash': ['hubbard squash'],
	'Mandarin Orange': ['mandarin orange'],
	Mango: ['mango'],
	Papayas: ['papayas', 'papaya'],
	Pumpkin: ['pumpkin'],
	'Valencia Orange': ['valencia orange'],
	'Bell Peppers': ['bell peppers', 'bell pepper'],
	'Celery Hearts': ['celery hearts', 'celery heart'],
	'Chicory Greens': ['chicory greens', 'chicory green'],
	'Chili Peppers': ['chili peppers', 'chili pepper'],
	'Garden Peas': ['garden peas', 'garden pea'],
	Peas: ['peas', 'pea'],
	Romaine: ['romaine'],
	Sprouts: ['sprouts', 'sprout'],
	Squash: ['squash'],
	'Alfalfa Sprouts': ['alfalfa sprouts', 'alfalfa sprout'],
	'Bok Choy': ['bok choy'],
	Celeriac: ['celeriac'],
	Cilantro: ['cilantro'],
	'Iceberg Lettuce': ['iceberg lettuce'],
	'Jalapeno Peppers': ['jalapeno peppers', 'jalapeno pepper'],
	Jicama: ['jicama'],
	'Pea Pods': ['pea pods', 'pea pod'],
	Rosemary: ['rosemary'],
	Rutabagas: ['rutabagas', 'rutabaga'],
	'Spaghetti Squash': ['spaghetti squash'],
	Thyme: ['thyme'],
	'Wax Beans': ['wax beans', 'wax bean'],
	'White Mushrooms': ['white mushrooms', 'white mushroom'],
	'Garbanzo Beans': ['garbanzo beans', 'garbanzo bean'],
	'Lima Beans': ['lima beans', 'lima bean'],
	'Pinto Beans': ['pinto beans', 'pinto bean'],
	Plantains: ['plantains', 'plantain'],
	Taro: ['taro'],
	'Water Chestnuts': ['water chestnuts', 'water chestnut'],
	Amaranth: ['amaranth'],
	'Artichoke Hearts': ['artichoke hearts', 'artichoke heart'],
	'Baby Corn': ['baby corn'],
	'Bamboo Shoots': ['bamboo shoots', 'bamboo shoot'],
	Chayote: ['chayote'],
	'Chinese Spinach': ['chinese spinach'],
	'Crookneck Squash': ['crookneck squash'],
	'Cushaw Squash': ['cushaw squash'],
	Daikon: ['daikon'],
	'Endive Greens': ['endive greens', 'endive green'],
	'Escarole Greens': ['escarole greens', 'escarole green'],
	'Green Cabbage': ['green cabbage'],
	'Hearts of Palm': ['hearts of palm'],
	'Sugar Snap Peas': ['sugar snap peas', 'sugar snap pea'],
	'Yard-long Beans': ['yard-long beans', 'yard-long bean'],
	'Asparagus Tips': ['asparagus tips', 'asparagus tip'],
	Cantaloupe: ['cantaloupe'],
	'Honeydew Melon': ['honeydew melon'],
	'String Beans': ['string beans', 'string bean'],
	'White Potatoes': ['white potatoes', 'white potato'],
};
