import React from 'react';
import { Checkbox } from './Checkbox';
import { isIframed } from './isIframed';

export function InstructionCheckbox({ step,  }: { step: string;  }) {
	const [checked, setChecked] = React.useState(false);
	return (
		<div>
			<Checkbox
				checked={checked}
				onChange={() => setChecked(!checked)}
				formSx={{ alignItems: 'flex-start' }}
			>
				<li
					style={{
						fontSize: isIframed() ? '1.5rem' : '1rem',
						marginTop: isIframed() ? '4px' : '10px',
						textDecoration: checked ? 'line-through' : 'unset',
						marginLeft: '24px'
					}}
				>
					{step}
				</li>
			</Checkbox>
		</div>
	);
}
