interface Unit {
	shorthand: string[];
	plural?: string;
	symbol?: string;
}

export const units: Record<string, Unit> = {
	// cooking measurements
	cup: {
		shorthand: ['cup', 'Cup', 'c', 'c.', 'C', 'Cups', 'cups'],
		plural: 'cups',
		symbol: 'cup',
	},
	tablespoon: {
		shorthand: [
			'tbs',
			'tbsp',
			'tbspn',
			'T',
			'T.',
			'Tablespoons',
			'Tablespoon',
			'tablespoons',
			'tablespoon',
		],
		plural: 'tablespoons',
		symbol: 'Tbs',
	},
	teaspoon: {
		shorthand: ['teaspoon', 'tsp', 'tspn', 't', 't.', 'teaspoons'],
		plural: 'teaspoons',
		symbol: 'tsp',
	},
	package: { shorthand: ['package', 'pkg', 'pkgs', 'packages'], plural: 'packages' },
	// cooking units
	inch: { shorthand: ['inch', 'inches'], plural: 'inches' },
	bag: { shorthand: ['bag', 'bags'], plural: 'bags' },
	box: { shorthand: ['box', 'boxes'], plural: 'boxes' },
	can: { shorthand: ['can', 'cans'], plural: 'cans' },
	clove: { shorthand: ['clove', 'cloves'], plural: 'cloves' },
	stick: { shorthand: ['stick', 'sticks'], plural: 'sticks' },
	piece: { shorthand: ['piece', 'pcs', 'pcs.', 'pieces'], plural: 'pieces' },
	pinch: { shorthand: ['pinch', 'pinches'], plural: 'pinches' },
	slice: { shorthand: ['slice', 'slices'], plural: 'slices' },
	stalk: { shorthand: ['stalk', 'stalks'], plural: 'stalks' },
	small: { shorthand: ['small'] },
	medium: { shorthand: ['medium'] },
	large: { shorthand: ['large'] },
	// imperial
	gallon: { shorthand: ['gallon', 'gal', 'gallons'], plural: 'gallons', symbol: 'gal' },
	ounce: { shorthand: ['ounce', 'oz', 'oz.', 'ounces'], plural: 'ounces', symbol: 'oz' },
	pint: {
		shorthand: ['pint', 'pt', 'pts', 'pt.', 'pints'],
		plural: 'pints',
		symbol: 'pt',
	},
	pound: {
		shorthand: ['pound', 'lb', 'lb.', 'lbs', 'lbs.', 'Lb', 'Lbs', 'pounds'],
		plural: 'pounds',
		symbol: 'lb',
	},
	quart: {
		shorthand: ['quart', 'qt', 'qt.', 'qts', 'qts.', 'quarts'],
		plural: 'quarts',
		symbol: 'qt',
	},
	// metric
	gram: { shorthand: ['gram', 'g', 'g.', 'grams'], plural: 'grams', symbol: 'g' },
	kilogram: {
		shorthand: ['kilogram', 'kg', 'kg.', 'Kg', 'Kg.', 'kilograms'],
		plural: 'kilograms',
		symbol: 'kg',
	},
	liter: {
		shorthand: ['liter', 'l', 'l.', 'lt', 'Lt', 'LT', 'L', 'L.', 'liters'],
		plural: 'liters',
		symbol: 'lt',
	},
	milligram: {
		shorthand: ['milligram', 'mg', 'mg.', 'milligrams'],
		plural: 'milligrams',
		symbol: 'mg',
	},
	milliliter: {
		shorthand: ['milliliter', 'ml', 'ml.', 'mL', 'mL.', 'milliliters'],
		plural: 'milliliters',
		symbol: 'ml',
	},
};
