import { useScrollTrigger } from '@mui/material';
import { ReactElement, cloneElement } from 'react';

interface Props {
	children: ReactElement;
}

export function ElevationScroll(props: Props) {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	return cloneElement(children, {
		elevation: trigger ? 4 : 0,
	});
}
