import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() => import('./Home').then(({ Home }) => ({ default: Home })));

export function Home() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
