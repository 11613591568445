import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export function Loading() {
	return (
		<Box sx={{ top: 0, left: 0, position: 'absolute', height: '100%', width: '100%' }}>
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		</Box>
	);
}
