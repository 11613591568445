import React from 'react';
import { styled } from '@mui/material';

const StyledHeading = styled('h3')`
	padding: 0;
	height: 36px;
	margin: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${props => props.theme.palette.text.primary};
`;

interface SectionHeadingProps extends React.HTMLAttributes<HTMLHeadElement> {
	children?: React.ReactNode;
}

export const SectionHeading = React.forwardRef<any, SectionHeadingProps>((props, ref) => (
	<StyledHeading ref={ref} {...props} />
));
