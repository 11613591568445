import { Button, ButtonGroup, Typography } from '@mui/material';
// @ts-ignore
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { ImagePreview } from './ImagePreview';
import React from 'react';
import { Loading } from '../Loading';
import { Footer, StyledCamera } from './styled';
import { BackendApi } from 'Api';

export function CameraModule(props: {
	psm: number;
	onChange: (image: string) => void;
	cancel: () => void;
}) {
	const [loading, setLoading] = React.useState(false);
	const [cameraLoaded, setCameraLoaded] = React.useState(false);
	const [error, setError] = React.useState('');
	const [image, setImage] = React.useState('');
	const [croppedImage, setCroppedImage] = React.useState('');

	return (
		<>
			{!image ? (
				<StyledCamera cameraLoaded={cameraLoaded}>
					<Camera
						idealFacingMode={FACING_MODES.ENVIRONMENT}
						isImageMirror={false}
						onTakePhoto={setImage}
						onCameraStart={() => setCameraLoaded(true)}
						imageType={IMAGE_TYPES.JPG}
						imageCompression={1}
					/>
				</StyledCamera>
			) : (
				<ImagePreview
					image={image}
					onCrop={setCroppedImage}
					onClear={() => {
						setImage('');
						setError('');
						setCameraLoaded(false);
					}}
				/>
			)}
			{loading && <Loading />}
			{error && (
				<Typography sx={theme => ({ margin: '20px', color: theme.palette.text.primary })}>
					{error}
				</Typography>
			)}
			<Footer>
				<ButtonGroup>
					<Button variant="contained" onClick={onDone} disabled={!image || loading}>
						Get Text
					</Button>
					<Button
						onClick={() => {
							setImage('');
							setLoading(false);
							setError('');
						}}
					>
						Retake
					</Button>
					<Button onClick={props.cancel}>Cancel</Button>
				</ButtonGroup>
			</Footer>
		</>
	);

	async function onDone() {
		setError('');
		setLoading(true);
		const result = await BackendApi.parseImage(
			croppedImage || image,
			300,
			props.psm
		).catch(handleError);
		setLoading(false);
		if (result === undefined) {
			return;
		}
		if (!result) {
			setError('No text found!');
			return;
		}
		props.onChange(result);
	}

	function handleError(e: any): undefined {
		switch (e?.response?.status) {
			case 400:
				setError('There was an error reading the image.');
				break;
			case 404:
				setError('The image was not able to be parsed.');
				break;
			case 401:
				setError('Unauthorized.');
				break;
			default:
				if (e.message === 'Network Error') {
					setError('Too many requests, try again later.');
				} else {
					setError('Something went wrong.');
				}
				break;
		}
		return undefined;
	}
}
