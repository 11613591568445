import React from 'react';
import MuiPopover, { PopoverOrigin } from '@mui/material/Popover';

interface Props {
	open: boolean;
	onClose(): void;
	anchorEl?: HTMLButtonElement;
	placement?: PopoverOrigin;
	children?: React.ReactNode;
}

export function Popover(props: Props) {
	const { open, onClose, anchorEl, placement, children } = props;

	return (
		<MuiPopover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={
				placement || {
					vertical: 'bottom',
					horizontal: 'left',
				}
			}
			sx={{ width: '100%' }}
		>
			<div style={{ padding: '10px' }}>{children}</div>
		</MuiPopover>
	);
}
