import { initializeApp, FirebaseOptions, FirebaseApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import {
	Database,
	getDatabase,
	ref,
	push,
	update,
	onValue,
	set,
	get,
	child,
	remove,
	query,
	orderByChild,
	limitToFirst,
	startAfter,
	limitToLast,
	increment,
} from 'firebase/database';
import { auth as authUI } from 'firebaseui';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

interface Api {
	app: FirebaseApp;
	db: Database;
	auth: Auth;
	authUI: authUI.AuthUI;
	userId: string;
	householdId?: string;
}

const firebaseConfigProd: FirebaseOptions = {
	apiKey: 'AIzaSyCG0BU-1irc8iHUrmVGK5oUWCNbQmsw1HY',
	authDomain: 'reciplan-c54e3.firebaseapp.com',
	projectId: 'reciplan-c54e3',
	storageBucket: 'reciplan-c54e3.appspot.com',
	messagingSenderId: '533708073002',
	appId: '1:533708073002:web:bf771c8dd1179de4253b53',
	databaseURL: 'https://reciplan-c54e3-default-rtdb.firebaseio.com/',
};
const firebaseConfigTest: FirebaseOptions = {
	apiKey: 'AIzaSyBAHbwjdXrI3hJcYaMVjxy9YIbmevGe05w',
	authDomain: 'reciplan-test-cc7fa.firebaseapp.com',
	projectId: 'reciplan-test-cc7fa',
	storageBucket: 'reciplan-test-cc7fa.appspot.com',
	messagingSenderId: '1066693280046',
	appId: '1:1066693280046:web:d45466e3025689cd49babf',
	databaseURL: 'https://reciplan-test-cc7fa-default-rtdb.firebaseio.com/',
};

const recaptcha =
	window.location.hostname === 'localhost'
		? '6Lc-z3AfAAAAAEdLY9x_orZYIcAN_hugwsdv5jZ4'
		: '6Lfj23AfAAAAAF9CQATcgwjxAkjjyTaW19ONlamg';

const app = initializeApp(
	window.location.hostname === 'localhost' ? firebaseConfigTest : firebaseConfigProd
);

const auth = getAuth(app);

initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider(recaptcha),
	isTokenAutoRefreshEnabled: true,
});

export const api = {
	app,
	auth,
	db: getDatabase(app),
	authUI: new authUI.AuthUI(auth),
} as Api;

export const db = {
	ref,
	push,
	update,
	onValue,
	set,
	get,
	child,
	remove,
	query,
	orderByChild,
	limitToFirst,
	limitToLast,
	startAfter,
	increment,
};

export function configureUserId(userId: string) {
	api.userId = userId;
}

export function configureHouseholdId(householdId: string | undefined) {
	api.householdId = householdId;
}
