import React from 'react';
import { Aisle, AislesApi, GroceriesApi, defaultAisles } from 'Api';
import { Form } from './Form';
import { Modal, ModalProps, ModalFooter, ModalBody } from './Modal';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Typography, useTheme } from '@mui/material';
import { useUndo } from 'Home/Groceries/Undo';
import { GroceryItemProps } from 'Home/Groceries/GroceryItem';

interface Props extends ModalProps {
	aisles: Aisle[];
	groceryItem?: GroceryItemProps;
}

const Body = styled(ModalBody)`
	min-width: 400px;
`;

export function AddToAisle(props: Props) {
	const { groceryItem, aisles, ...modalProps } = props;
	const [aisleLabel, setAisleLabel] = React.useState<string | null>('');
	const [customAisle, setCustomAisle] = React.useState('');
	const theme = useTheme();
	const undo = useUndo();

	const allAisles = [...aisles, ...defaultAisles];
	const allUniqueAisles = allAisles.filter((aisle, index) => {
		return (
			allAisles.findIndex(a => a.label.toLowerCase() === aisle.label.toLowerCase()) ===
			index
		);
	});

	return (
		<Modal
			{...modalProps}
			modalTitle={
				<>
					Which aisle do you want
					<span style={{ margin: '0px 5px', color: theme.palette.primary.main }}>
						{groceryItem?.ingredientName}
					</span>
					to appear?
				</>
			}
			Body={Body}
		>
			<Form onSubmit={save}>
				<Stack>
					<Typography>
						Select an existing aisle or enter a name to create your own.
					</Typography>
					<Autocomplete
						freeSolo
						onChange={(_, value) => setAisleLabel(value)}
						onInputChange={(_, newInputValue) => {
							setAisleLabel(null);
							setCustomAisle(newInputValue);
						}}
						options={allUniqueAisles.map(a => a.label).sort()}
						renderInput={params => (
							<MuiTextField {...params} label="Aisle" margin="normal" />
						)}
					/>
				</Stack>
				<ModalFooter>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<ButtonGroup>
							<Button type="submit" variant="contained">
								{!aisleLabel && customAisle ? 'Create' : 'Save'}
							</Button>
							<Button type="button" onClick={() => props.onClose()}>
								Cancel
							</Button>
						</ButtonGroup>
						<Button type="button" onClick={removeItems}>
							Delete
						</Button>
					</div>
				</ModalFooter>
			</Form>
		</Modal>
	);

	async function save() {
		const label = customAisle?.trim() || aisleLabel?.trim();

		if (!label || !groceryItem) {
			return;
		}

		changeIngredientAisle(groceryItem.ingredientName, label, aisles);
		props.onClose();
	}

	async function removeItems() {
		if (!groceryItem) {
			return;
		}
		await GroceriesApi.remove(...groceryItem.ingredients);
		undo.add({
			undo: () => GroceriesApi.update(...groceryItem.ingredients),
			redo: () => GroceriesApi.remove(...groceryItem.ingredients),
		});
		props.onClose();
	}
}

export async function changeIngredientAisle(
	ingredient: string,
	aisleLabel: string,
	aisles: Aisle[]
) {
	const aisleId = aisleLabel.toLowerCase();

	const existingIngredient = aisles.find(e =>
		e.ingredients?.some(i => i.toLowerCase() === ingredient.toLowerCase())
	);
	if (existingIngredient) {
		existingIngredient.ingredients = existingIngredient.ingredients?.filter(
			i => i.toLowerCase() !== ingredient.toLowerCase()
		);
		await AislesApi.update(existingIngredient);
	}

	const existingAisle = aisles.find(e => e.label.toLowerCase() === aisleId);
	if (existingAisle) {
		existingAisle.ingredients = [
			...(existingAisle.ingredients?.filter(
				i => i.toLowerCase() !== ingredient.toLowerCase()
			) || []),
			ingredient,
		];
		await AislesApi.update(existingAisle);
		return;
	}

	const newAisle: Omit<Aisle, 'id'> = {
		label: aisleLabel,
		ingredients: [ingredient],
	};

	await AislesApi.create(newAisle);
}
