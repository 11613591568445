import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() =>
	import('./UserSettings').then(({ UserSettingsPage }) => ({
		default: UserSettingsPage,
	}))
);

export function UserSettingsPage() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
