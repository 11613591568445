import { produce, dairy, seafood, meat, bakery, cooking, snacks } from './DefaultAisles';
import { api, db } from './firebase';
import { removeId, toArray } from './util';

export interface Aisle {
	id: string;
	label: string;
	ingredients?: string[];
	order?: number;
}

const householdPath = () => `aisles/${api.householdId}`;

function listener(callback: (result: Aisle[]) => void) {
	db.onValue(db.ref(api.db, householdPath()), async snapshot => {
		if (!snapshot.exists()) {
			callback([]);
			return;
		}

		const data = snapshot.val();
		callback(toArray(data));
	});
}

async function create(value: Omit<Aisle, 'id'>): Promise<Aisle> {
	const newRef = db.push(db.ref(api.db, householdPath()));
	await db.set(newRef, value);
	return { id: newRef.key!, ...value };
}

async function get(): Promise<Aisle[]> {
	const snapshot = await db.get(db.child(db.ref(api.db), householdPath()));
	if (snapshot.exists()) {
		return toArray(snapshot.val());
	}

	return [];
}

async function update(value: Aisle) {
	const newValue = { ...value };
	if (!newValue.id) {
		return;
	}
	const id = removeId(newValue);
	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.update(dbRef, newValue);
}

async function remove(id: string) {
	if (!id) {
		return;
	}

	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.remove(dbRef);
}

export const AislesApi = {
	listener,
	create,
	get,
	update,
	remove,
};

export const defaultAisles: Aisle[] = [
	{ label: 'Bakery', ingredients: bakery.split('\n') },
	{ label: 'Cooking', ingredients: cooking.split('\n') },
	{ label: 'Dairy', ingredients: dairy.split('\n') },
	{ label: 'Produce', ingredients: produce.split('\n') },
	{ label: 'Seafood', ingredients: seafood.split('\n') },
	{ label: 'Snacks', ingredients: snacks.split('\n') },
	{ label: 'Meat', ingredients: meat.split('\n') },
	{ label: 'Drinks', ingredients: [] },
	{ label: 'Frozen', ingredients: [] },
] as Aisle[];
