export function encodeEmail(email: string) {
	return email.replaceAll('.', ',');
}

export function decodeEmail(email: string) {
	return email.replaceAll(',', '.');
}

export function addId(id: string, obj: any) {
	return { ...obj, id };
}

export function removeId(obj: any) {
	const id = obj.id;
	delete obj.id;
	return id as string;
}

export function toArray<T>(obj: Record<string, T>) {
	const results: (T & { id: string })[] = [];
	for (const id of Object.keys(obj)) {
		results.push(addId(id, obj[id]));
	}
	return results;
}

export function toMap<T>(arr: T[]) {
	const obj = arr.reduce((children, item) => {
		if (!item) {
			return children;
		}
		const id = removeId(item);
		children[id] = item;
		return children;
	}, {} as Record<string, Omit<T, 'id'>>);
	return obj;
}

export async function fetchIds<T>(obj: any, fetcher: (id: string) => Promise<T>) {
	const results: T[] = [];
	for (const id of Object.keys(obj)) {
		const result = await fetcher(id).catch(() => null);
		if (result) {
			results.push(result);
		}
	}
	return results;
}
