import { Household, Meal, MealApi, MealTime, Recipe } from 'Api';

export async function createLeftovers(
	mealId: string,
	household: Household | undefined,
	recipe: Recipe | undefined,
	startDate: Date,
	mealTime: MealTime | null
) {
	let servings = recipe?.servings ?? 0;
	const members = household?.numberOfMembers ?? 0;
	if (!servings || !members || !mealTime) {
		return [];
	}

	servings = servings - members;
	const leftovers: Meal[] = [];

	while (servings >= members) {
		const nextDay = startDate.addDays(leftovers.length + 1);
		const leftover = await MealApi.create(
			{
				date: nextDay,
				name: `${recipe!.name}`,
				mealTime: mealTime,
				parentMealId: mealId,
				isLeftovers: true,
			},
			undefined,
			undefined
		);
		servings = servings - members;
		leftovers.push(leftover);
	}

	return leftovers;
}
