import { Meal, MealTime } from 'Api';

export function sortMeals(meals: Meal[]) {
	return meals.sort((a, b) => {
		if (a.mealTime === MealTime.breakfast) {
			if (b.mealTime === MealTime.breakfast) {
				return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
			}
			return -1;
		}
		if (a.mealTime === MealTime.lunch) {
			if (b.mealTime === MealTime.lunch) {
				return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
			}
			return b.mealTime !== MealTime.breakfast ? -1 : 1;
		}
		if (a.mealTime === MealTime.dinner) {
			if (b.mealTime === MealTime.dinner) {
				return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
			}
			return b.mealTime !== MealTime.breakfast && b.mealTime !== MealTime.lunch ? -1 : 1;
		}
		if (a.mealTime === MealTime.dessert) {
			if (b.mealTime === MealTime.dessert) {
				return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
			}
			return b.mealTime !== MealTime.breakfast &&
				b.mealTime !== MealTime.lunch &&
				b.mealTime !== MealTime.dinner
				? -1
				: 1;
		}
		if (!a.mealTime) {
			if (!b.mealTime) {
				return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
			}
			return 1;
		}
		return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
	});
}
