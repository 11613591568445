import { api, db } from './firebase';
import { addId, removeId, toArray } from './util';

export interface Recipe {
	id: string;
	name: string;
	sourceName?: string;
	sourceUrl?: string;
	imageUrl?: string;
	inactiveTime?: number | null;
	activeTime?: number | null;
	servings?: number | null;
	ingredients?: string;
	instructions?: string;
	prepIngredients?: string;
	prepInstructions?: string;
	notes?: string;
	tags?: string[];
	uses?: number;
}

const householdPath = () => `recipes/${api.householdId}`;

function listener(callback: (result: Recipe[]) => void) {
	const ref = db.query(db.ref(api.db, householdPath()), db.orderByChild('name'));
	db.onValue(ref, async snapshot => {
		const results: Recipe[] = [];
		snapshot.forEach(child => {
			const result = child.val();
			results.push(addId(child.key!, result));
		});
		callback(results);
	});
}

async function create(value: Omit<Recipe, 'id'>): Promise<Recipe> {
	const newValue = { ...value };
	removeId(newValue);
	const newRef = db.push(db.ref(api.db, householdPath()));
	await db.set(newRef, newValue);
	return addId(newRef.key!, newValue);
}

async function bulkCreate(...values: Omit<Recipe, 'id'>[]) {
	const objects = values.reduce((children, value) => {
		const newRef = db.push(db.ref(api.db, householdPath()));
		children[newRef.key!] = value;
		return children;
	}, {} as Record<string, Omit<Recipe, 'id'>>);
	await db.update(db.ref(api.db, householdPath()), objects);
}

async function get(): Promise<Recipe[]> {
	const snapshot = await db.get(db.child(db.ref(api.db), householdPath()));
	if (snapshot.exists()) {
		return toArray<Recipe>(snapshot.val());
	}
	return [];
}

async function getPage(orderBy: string): Promise<Recipe[]> {
	const ref = db.query(db.ref(api.db, householdPath()), db.orderByChild(orderBy));
	const res = await db.get(ref);

	const results: Recipe[] = [];
	res.forEach(child => {
		results.push(addId(child.key!, child.val()));
	});
	return results;
}

async function update(value: Partial<Recipe>): Promise<void> {
	const newValue = { ...value };
	if (!newValue.id) {
		return;
	}

	const id = removeId(newValue);
	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.update(dbRef, newValue);
}

async function updateUses(value: string, direction: number) {
	if (!value) {
		return;
	}

	const dbRef = db.ref(api.db, `${householdPath()}/${value}`);
	await db.update(dbRef, { uses: db.increment(direction) });
}

async function remove(id: string) {
	if (!id) {
		return;
	}

	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.remove(dbRef);
}

export const RecipeApi = {
	listener,
	create,
	bulkCreate,
	get,
	getPage,
	update,
	updateUses,
	remove,
};
