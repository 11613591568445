export const produce = `Apricots
Bananas
Artichokes
Arugula
Red Lettuce Leaves
Asparagus
Avocados
Basil
Beets
Black-eyed Peas
Red Chile
Cherry Tomatoes
Blood Oranges
Ginger
Broccoli
Carrots
Cauliflower
Chard
Cherries
Corn
Cucumber
English Cucumber
Eggplant
Russet Potatoes
Fava Beans
Fennel
Fiddleheads
Garlic
Figs
Grapefruits
Green Onions
Yellow Onions
Kohlrabi
Kumquats
Medjool Dates
Morels
Mushrooms
Navel Oranges
Nectarines
Nettles
Lettuce
Okra
Parsley
Passion Fruit
Pea Beans
Peaches
Plums
Potatoes
Radish
Raspberries
Rhubarb
Spinach
Spring Onions
Strawberries
Tomatoes
Turnips
Apples
Blackberries
Blueberries
Boysenberries
Cabbage
Collards
Eggplants
Grapes
Kale
Lemons
Melons
Mustard
Peppers
Persimmons
Sapote
Summer Squash
Tomatillos
Valencia Oranges
Asian Pears
Brussels Sprouts
Carambola
Celery
Cherimoyas
Chili Pepper
Cranberries
Edamame
Green Beans
Guava
Kiwi
Leeks
Lemongrass
Limes
Onions
Oranges
Parsnips
Pears
Pineapples
Pomegranates
Pumpkins
Quinces
Radishes
Rapini
Rutabaga
Salsify
Scallions
Shallots
Sweet Potatoes
Yams
Green Peas
Tangelos
Tangerines
Winter Squash
Chayote Squash
Collard Greens
Cucumbers
Endive
Green Bell Peppers
Jalapenos
Mustard Greens
Snap Peas
Snow Peas
Swiss Chard
Watercress
Zucchini
Acerola
Dragon Fruit
Lingonberries
Radicchio
Red Apples
Granny Smith Apples
Red Beets
Red Cabbage
Red Currants
Red Grapefruits
Red Grapes
Red Onions
Red Pears
Red Peppers
Red Potatoes
Rose Hip
Watermelon
Acorn Squash
Butternut Squash
Clementine
Hubbard Squash
Mandarin Orange
Mango
Papayas
Pumpkin
Valencia Orange
Bell Peppers
Celery Hearts
Chicory Greens
Chili Peppers
Garden Peas
Peas
Romaine
Sprouts
Squash
Alfalfa Sprouts
Bok Choy
Celeriac
Cilantro
Iceberg Lettuce
Jalapeno Peppers
Jicama
Pea Pods
Rosemary
Rutabagas
Spaghetti Squash
Thyme
Wax Beans
White Mushrooms
Garbanzo Beans
Lima Beans
Pinto Beans
Plantains
Taro
Water Chestnuts
Amaranth
Artichoke Hearts
Baby Corn
Bamboo Shoots
Chayote
Chinese Spinach
Crookneck Squash
Cushaw Squash
Daikon
Endive Greens
Escarole Greens
Green Cabbage
Hearts of Palm
Sugar Snap Peas
Yard-long Beans
Asparagus Tips
Cantaloupe
Honeydew Melon
String Beans
White Potatoes
Lemon Juice
Garlic Clove
Garlic Cloves`;
