import { Refresh } from '@mui/icons-material';
import { CircularProgress, Paper } from '@mui/material';
import { useIsSmallScreen } from 'Components';
import React from 'react';

const maxDrag = 200;

export const PullToRefresh: React.FC = props => {
	const smallScreen = useIsSmallScreen();
	const [touchStart, setTouchStart] = React.useState<React.Touch>();
	const [drag, setDrag] = React.useState(0);
	const [dragging, setDragging] = React.useState(false);
	const [busy, setBusy] = React.useState(false);

	if (!smallScreen) {
		return <>{props.children}</>;
	}

	return (
		<div
			style={{
				height: dragging ? '100vh' : 'unset',
				overflowY: dragging ? 'hidden' : 'auto',
				position: 'relative',
			}}
			onTouchStart={onTouchStart}
			onTouchMove={onTouchMove}
			onTouchEnd={onTouchEnd}
		>
			{props.children}
			<div
				style={{
					position: 'absolute',
					top: drag / 1.75 - 44,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					zIndex: 5000,
				}}
			>
				<Paper
					sx={{
						borderRadius: '50%',
						height: '40px',
					}}
				>
					{busy ? (
						<CircularProgress size={40} />
					) : (
						<Refresh
							sx={{
								width: '40px',
								height: '40px',
								transform: `rotate(${drag * 2}deg)`,
							}}
							color="primary"
						/>
					)}
				</Paper>
			</div>
		</div>
	);

	function reset() {
		setTouchStart(undefined);
		setDrag(0);
		setDragging(false);
		setBusy(false);
	}

	function onTouchStart(event: React.TouchEvent<any>) {
		const anchor = document.querySelector('#back-to-top-anchor');
		if (anchor?.getClientRects()[0].top! !== 0) {
			return;
		}

		const tableAnchor = document.querySelector('#table-anchor');
		if (tableAnchor?.getClientRects()[0].top! < 90) {
			return;
		}
		setTouchStart(event.touches[0]);
	}

	function onTouchMove(event: React.TouchEvent) {
		if (!touchStart) {
			return;
		}
		const drag = Math.max(0, event.touches[0].clientY - touchStart.clientY);
		if (drag !== 0) {
			setDrag(Math.min(drag, maxDrag));
			setDragging(true);
		} else if (!dragging) {
			reset();
		}
	}

	function onTouchEnd() {
		if (busy) {
			return;
		}
		if (drag >= maxDrag * 0.95) {
			setBusy(true);
			window.location.reload();
		} else {
			reset();
		}
	}
};
