import { parseIngredients } from 'Components';
import { Ingredient } from 'Components/parseIngredient/parse';
import { api, db } from './firebase';
import { Recipe } from './RecipeApi';
import { removeId, toArray } from './util';

export interface PantryItem {
	id: string;
	label: string;
	quantity: number | null;
	unit?: string;
}

const householdPath = () => `pantry/${api.householdId}`;

function listener(callback: (result: PantryItem[]) => void) {
	db.onValue(db.ref(api.db, householdPath()), async snapshot => {
		if (!snapshot.exists()) {
			callback([]);
			return;
		}

		const data = snapshot.val();
		callback(toArray(data));
	});
}

async function create(value: Omit<PantryItem, 'id'>): Promise<PantryItem> {
	const newRef = db.push(db.ref(api.db, householdPath()));
	await db.set(newRef, value);
	return { id: newRef.key!, ...value };
}

async function get(): Promise<PantryItem[]> {
	const snapshot = await db.get(db.child(db.ref(api.db), householdPath()));
	if (snapshot.exists()) {
		return toArray(snapshot.val());
	}
	return [];
}

async function update(value: PantryItem) {
	const newValue = { ...value };
	if (!newValue.id) {
		return;
	}
	const id = removeId(newValue);
	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.update(dbRef, newValue);
}

async function remove(id: string) {
	if (!id) {
		return;
	}

	const dbRef = db.ref(api.db, `${householdPath()}/${id}`);
	await db.remove(dbRef);
}

export async function updateUses(
	recipe: Recipe,
	pantry: PantryItem[],
	direction: number
) {
	if (!recipe.id) {
		return;
	}
	const prepIngredients = parseIngredients(recipe.prepIngredients);
	const ingredients = parseIngredients(recipe.ingredients);
	for (const ingredient of [...prepIngredients, ...ingredients]) {
		const pantryItem = pantry.find(
			p => p.label.toLowerCase() === ingredient.name?.toLowerCase()
		);
		const delta = getQuantityDelta(ingredient, pantryItem, direction);
		if (delta !== 0 && pantryItem) {
			const dbRef = db.ref(api.db, `${householdPath()}/${pantryItem.id}`);
			await db.update(dbRef, { quantity: db.increment(-delta) });
		}
	}
}

function getQuantityDelta(
	ingredient: Ingredient,
	pantryItem: PantryItem | undefined,
	direction: number
) {
	if (
		!pantryItem ||
		pantryItem.quantity == null ||
		ingredient.quantity == null ||
		ingredient.unit !== pantryItem.unit
	) {
		return 0;
	}

	return direction * ingredient.quantity;
}

export const PantryApi = {
	listener,
	create,
	get,
	update,
	remove,
	updateUses,
};
