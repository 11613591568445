import React from 'react';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

interface Props extends Omit<TextFieldProps, 'onChange'> {
	onChange?(value: string): void;
	startAdornment?: React.ReactNode;
	endAdornment?: React.ReactNode;
	shrinkLabel?: boolean;
	readOnly?: boolean;
	autoComplete?: string;
}

export function TextField(props: Props) {
	const {
		onChange,
		variant,
		endAdornment,
		startAdornment,
		shrinkLabel,
		readOnly,
		autoComplete,
		...muiProps
	} = props;
	return (
		<MuiTextField
			margin="normal"
			{...muiProps}
			sx={{ width: props.fullWidth ? '100%' : '300px', ...muiProps.sx }}
			onChange={onChange ? e => onChange(e.currentTarget.value) : undefined}
			InputProps={{
				endAdornment: endAdornment && (
					<InputAdornment position="end">{endAdornment}</InputAdornment>
				),
				startAdornment: startAdornment && (
					<InputAdornment position="start">{startAdornment}</InputAdornment>
				),
				readOnly,
				autoComplete: 'off' || autoComplete,
			}}
			InputLabelProps={{
				shrink: shrinkLabel,
			}}
		/>
	);
}
