import { useState, useMemo } from 'react';
import { AppContext } from './AppContext';
import {
	AppBarProvider,
	ElevationScroll,
	ErrorBoundary,
	isIframed,
	Loading,
	reciplanTheme,
	useIsSmallScreen,
} from './Components';
import { Masthead } from './Masthead';
import {
	createTheme,
	ThemeProvider,
	useMediaQuery,
	Toolbar,
	CssBaseline,
	Box,
	Container,
	PaletteMode,
} from '@mui/material';
import { Router } from './Router';
import { BrowserRouter } from 'react-router-dom';
import { PullToRefresh } from 'PullToRefresh';
import { BottomNav } from 'Masthead/NavigationDrawer';

export function App() {
	const smallScreen = useIsSmallScreen();
	const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [userTheme, setUserTheme] = useState<string | undefined>(
		localStorage.getItem('last-theme') || 'device'
	);

	const theme = useMemo(() => {
		let currentUserTheme: PaletteMode;
		switch (userTheme) {
			case 'light':
			case 'dark':
				currentUserTheme = userTheme;
				break;
			default:
				currentUserTheme = browserDarkMode ? 'dark' : 'light';
				break;
		}
		return createTheme({
			palette: {
				...reciplanTheme,
				mode: isIframed() ? 'light' : currentUserTheme,
			},
		});
	}, [browserDarkMode, userTheme]);

	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<AppBarProvider>
					<PullToRefresh>
						<AppContext
							onThemeChange={theme => {
								setUserTheme(theme);
								localStorage.setItem('last-theme', theme || '');
							}}
						>
							{loading => (
								<div style={{ display: 'flex' }}>
									<CssBaseline />
									<ElevationScroll>
										<Masthead />
									</ElevationScroll>
									<div style={{ width: '100%' }}>
										<Toolbar id="back-to-top-anchor" />
										<Container disableGutters={smallScreen}>
											<Box>
												{loading ? (
													<Loading />
												) : (
													<ErrorBoundary>
														<Router />
														<BottomNav />
													</ErrorBoundary>
												)}
											</Box>
										</Container>
									</div>
								</div>
							)}
						</AppContext>
					</PullToRefresh>
				</AppBarProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}
