import * as React from 'react';
import { Button, styled } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';

const StyledHeader = styled('h2')`
	font-size: ${props => props.theme.typography.h4};
	margin: 10px 0;
`;

interface Props {
	goBack?: boolean;
	children?: React.ReactNode;
	id?: string;
}

export const PageTitle: React.FC<Props> = props => {
	const navigate = useNavigate();
	return (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<StyledHeader id={props.id}>{props.children}</StyledHeader>
			{props.goBack && (
				<div>
					<Button onClick={() => navigate('/')}>
						<NavigateBeforeIcon />
						Back
					</Button>
				</div>
			)}
		</div>
	);
};
