import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled, Chip, Stack } from '@mui/material';
import { allMealTimes, MealTime } from 'Api';
import { useAppContext } from 'AppContext';
import React from 'react';

export const StyledInput = styled('input')`
	background-color: ${props => props.theme.palette.background.default};
	color: ${props => props.theme.palette.text.primary};
	border: 1px solid ${props => props.theme.palette.primary.main};
	outline: none;
	font-size: 18px;
	font-family: inherit;
	padding: 4px 10px;
	border-radius: 16px;
`;

interface Props {
	visibleTags: Set<string> | undefined;
	selectedTags?: string[];
	onChange?(tags: string[]): void;
	onCreate?(newTag: string): void;
}

export function RecipeTagChips(props: Props) {
	const [isCreating, setIsCreating] = React.useState(false);
	const [newTag, setNewTag] = React.useState('');
	const { addTag } = useAppContext();

	return (
		<Stack direction="row" spacing={1} flexWrap="wrap" sx={{ marginBottom: '8px' }}>
			{Array.from(props.visibleTags || new Set([])).map(tag => {
				const on = props.selectedTags?.includes(tag);
				const mealTime = getMealTime([tag]);
				return (
					<Chip
						key={tag}
						label={tag.toUpperCase()}
						color={ on ? 'primary' : 'default'}
						variant={on ? 'filled' : 'outlined'}
						style={{ marginBottom: '8px', marginLeft: '8px' }}
						onClick={
							props.onChange && props.selectedTags
								? () => {
										const tags = props.selectedTags!.filter(t => t !== tag);
										if (!on) {
											tags.push(tag);
										}
										props.onChange!(tags);
								  }
								: undefined
						}
						icon={mealTime && <AccessTimeIcon />}
					/>
				);
			})}
			{props.onCreate && !isCreating && (
				<Chip
					label="+"
					color="primary"
					variant="outlined"
					onClick={() => setIsCreating(true)}
				/>
			)}
			{isCreating && (
				<div>
					<StyledInput
						value={newTag}
						onChange={e => setNewTag(e.currentTarget.value)}
						autoFocus
						onBlur={createNewTag}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								e.stopPropagation();
								e.preventDefault();
								createNewTag();
							}
						}}
					/>
				</div>
			)}
		</Stack>
	);

	function createNewTag() {
		const tagToCreate = newTag.toLowerCase().trim();
		setIsCreating(false);
		setNewTag('');
		if (!tagToCreate) {
			return;
		}
		addTag(tagToCreate);
		props.onCreate!(tagToCreate);
	}
}

export function getMealTime(tags: string[] | undefined) {
	return tags?.find(t => allMealTimes.has(t as MealTime)) as MealTime;
}
