import { units } from './units';
import { ingredients } from './ingredients';

export interface Ingredient extends IngredientQuantity {
	fullIngredient: string;
	name: string;
	choice?: boolean;
	unit?: string;
	details?: string;
}

export interface IngredientQuantity {
	quantity?: number | null;
	minQuantity?: number;
	maxQuantity?: number;
}

export function parseIngredients(recipeString: string | undefined) {
	if (!recipeString?.trim()) {
		return [];
	}
	return recipeString.split('\n').map(r => parseIngredient(r));
}

export function parseIngredient(recipeString: string): Ingredient {
	const ingredientLine = recipeString.trim();

	const [choice, fullIngredient] = findChoice(ingredientLine);
	const [quantity, unitAndIngredient] = findQuantity(fullIngredient);
	const [unit, ingredientAndDetails] = findUnit(unitAndIngredient);
	const [name, details] = findName(ingredientAndDetails);

	return {
		...quantity,
		fullIngredient,
		name,
		choice,
		details,
		unit,
	};
}

function findChoice(ingredientLine: string): [boolean, string] {
	if (ingredientLine.toLowerCase().startsWith('choice:')) {
		const reg = /^choice:/i;
		return [true, ingredientLine.replace(reg, '').trim()];
	}
	return [false, ingredientLine];
}

function findQuantity(ingredientLine: string): [IngredientQuantity, string] {
	const number = '\\d*\\.?\\d';
	const fraction = '\\d+/\\d+';
	const unicode = '[^\\u0000-\\u007F]';
	const space = '\\s';
	const range1 = '-';
	const range2 = ' - ';
	const range3 = ' to ';

	const numbers = [
		[number, fraction].join(space), // 1 1/2
		[number, unicode].join(space), // 1 ½
		'\\d+' + unicode, // 1½
		unicode, // ½
		fraction, // 1/2
		number, // 1.5
	];

	const ranges: string[] = [];
	numbers.forEach(n1 => {
		numbers.forEach(n2 => {
			ranges.push([n1, n2].join(range1));
			ranges.push([n1, n2].join(range2));
			ranges.push([n1, n2].join(range3));
		});
	});

	const rangePattern = '^(' + ranges.join(')|(') + ')';
	const rangeRegex = new RegExp(rangePattern, 'g');
	const rangeMatch = ingredientLine.match(rangeRegex);
	if (rangeMatch) {
		const quantity: IngredientQuantity = {};
		const rangePartsRegex = /(.*)( - | to |-)(.*)/g;
		const rangePartsMatch = rangePartsRegex.exec(rangeMatch[0]);
		if (rangePartsMatch) {
			quantity.minQuantity = convertFractionToNumber(rangePartsMatch[1].trim());
			quantity.maxQuantity = convertFractionToNumber(rangePartsMatch[3].trim());
		}
		return [quantity, ingredientLine.replace(rangeMatch[0], '').trim()];
	}

	const numericPattern = '^(' + numbers.join(')|(') + ')';
	const numericRegex = new RegExp(numericPattern, 'g');
	const numericMatch = ingredientLine.match(numericRegex);
	if (numericMatch) {
		const quantity: IngredientQuantity = {
			quantity: convertFractionToNumber(numericMatch[0]),
		};
		return [quantity, ingredientLine.replace(numericMatch[0], '').trim()];
	}
	return [{}, ingredientLine];
}

function convertFractionToNumber(value: string) {
	const unicodeFraction = /([^\u0000-\u007F])/g;
	const unicodeMatch = value.match(unicodeFraction);
	if (unicodeMatch) {
		const unicode = unicodeMatch[0];
		const unicodeNumber = unicodeObj[unicode.trim()];
		value = value.replace(unicode, ` ${unicodeNumber}`);
	}

	const hasSpace = /(.*)\s+(.*)/g;
	const spaceMatch = hasSpace.exec(value);
	if (spaceMatch) {
		const [, whole, fraction] = spaceMatch;
		const [a, b] = fraction.split('/');
		const remainder = parseFloat(a) / parseFloat(b);
		return parseInt(whole) ? parseInt(whole) + remainder : remainder;
	} else {
		const [a, b] = value.split('/');
		return b ? parseFloat(a) / parseFloat(b) : parseFloat(a);
	}
}

function findUnit(fullIngredient: string): [string | undefined, string] {
	for (const unit of Object.keys(units)) {
		for (const shorthand of units[unit].shorthand) {
			const unitRegex = new RegExp(`^${shorthand} (of |)(.*)`);
			const unitMatch = unitRegex.exec(fullIngredient);
			if (unitMatch?.length === 3) {
				const name = unitMatch[2].trim();
				return [unit, name];
			}
		}
	}
	return [undefined, fullIngredient];
}

function findName(fullIngredient: string) {
	let name = fullIngredient;
	let details = '';

	const parensRegex = /(\(.*?\))/g;
	const parensMatch = name.match(parensRegex);
	if (parensMatch) {
		parensMatch.forEach(parens => {
			details += parens.trim();
			name = name.replace(parens, '').trim();
		});
	}

	const commaParts = name.split(',');
	if (commaParts.length > 1) {
		name = commaParts[0].trim();
		details += commaParts[1].trim();
	}

	const orRegex = /(.*) or( .*|$)/g;
	const orMatch = orRegex.exec(name);
	if (orMatch) {
		name = orMatch[1].trim();
		details += orMatch[2].trim();
	}

	if (name.endsWith(' to taste')) {
		name = name.replace('to taste', '').trim();
	}

	return [getBaseName(name), details];
}

function getBaseName(name: string) {
	for (const ingredient of Object.keys(ingredients)) {
		const names: string[] = (ingredients as any)[ingredient];
		if (names.find(n => n.toLowerCase() === name.toLowerCase())) {
			return ingredient;
		}
	}
	return name;
}

const unicodeObj: Record<string, string> = {
	'½': '1/2',
	'⅓': '1/3',
	'⅔': '2/3',
	'¼': '1/4',
	'¾': '3/4',
	'⅕': '1/5',
	'⅖': '2/5',
	'⅗': '3/5',
	'⅘': '4/5',
	'⅙': '1/6',
	'⅚': '5/6',
	'⅐': '1/7',
	'⅛': '1/8',
	'⅜': '3/8',
	'⅝': '5/8',
	'⅞': '7/8',
	'⅑': '1/9',
	'⅒': '1/10',
};
