import { useState } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
	StyledDateBlock,
	StyledDateToolbar,
	StyledDayPicker,
	StyledDays,
	StyledTodayDot,
} from './DatePicker.styled';
import PreviousIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import NextIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { Today } from '@mui/icons-material';
import { MealTime as MealTimeType } from 'Api';
import { useAppContext } from 'AppContext';
import { sortMeals } from '../sortMeals';
import { MealTimeChip } from '../MealTimeDropdown';
import { getMealDisplayName } from 'Components/getMealDisplayName';

export interface Props {
	mealTime: MealTimeType | undefined | null;
	mealId?: string;
	date: Date;
	onChange(date: Date): void;
}

export function DatePicker(props: Props) {
	const { meals } = useAppContext();
	const [week, setWeek] = useState(getDaysOfWeek(props.date));

	const startMonth = week[0].toLocaleString('en', {
		month: 'long',
	});

	const daysMeals = meals?.filter(m => m.date.isSameDate(props.date));

	return (
		<div>
			<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
				<StyledDateToolbar>
					<Typography sx={{ width: '120px' }}>{startMonth}</Typography>
					<div>
						<Tooltip title="Previous week">
							<IconButton onClick={() => changeWeek(-7)}>
								<PreviousIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Next week">
							<IconButton onClick={() => changeWeek(7)}>
								<NextIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Today">
							<IconButton onClick={today}>
								<Today />
							</IconButton>
						</Tooltip>
					</div>
				</StyledDateToolbar>
				<StyledDayPicker>
					<StyledDays>
						{week.map(day => {
							return (
								<StyledDateBlock
									key={day.toISOString()}
									faded={day.hasHappened()}
									selected={day.isSameDate(props.date)}
									onClick={() => props.onChange(day)}
								>
									<div>{day.toLocaleDateString('en', { weekday: 'short' })}</div>
									<div>
										<div>
											{day.getDate()}
											{day.isSameDate(new Date()) && (
												<StyledTodayDot selected={day.isSameDate(props.date)}>
													<div />
												</StyledTodayDot>
											)}
										</div>
									</div>
								</StyledDateBlock>
							);
						})}
					</StyledDays>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
							marginTop: '8px',
						}}
					>
						{sortMeals(daysMeals ?? [])?.map(meal => (
							<div
								key={meal.id}
								style={{
									display: 'flex',
									gap: '4px',
									opacity: meal.id === props.mealId ? 0.6 : 1,
								}}
							>
								<MealTimeChip
									label={meal.mealTime}
									size="small"
									sx={{ marginRight: '4px' }}
								/>
								{meal.id === props.mealId && '> '}
								{getMealDisplayName(meal)}
							</div>
						))}
					</div>
				</StyledDayPicker>
			</div>
		</div>
	);

	function today() {
		setWeek(getDaysOfWeek(new Date().getPastDayOfWeek(0)));
	}

	function changeWeek(offset: number) {
		const newWeek = week[0].addDays(offset);
		setWeek(getDaysOfWeek(newWeek));
	}

	function getDaysOfWeek(initialDate: Date) {
		const dates: Date[] = [];
		let dateToAdd = initialDate.getPastDayOfWeek(0);
		for (let i = 0; i < 7; i++) {
			dates.push(dateToAdd);
			dateToAdd = dateToAdd.addDays(1);
		}
		return dates;
	}
}
