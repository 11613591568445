import React from 'react';
import { Form, Modal, ModalProps, ModalFooter, TextField } from 'Components';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import { FeedbackApi } from 'Api';
import { useLocation } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ThumbDown, ThumbUp } from '@mui/icons-material';

interface Props extends ModalProps {
	onSend(): void;
	initialComment?: string;
	initialIsGood?: boolean;
}

export function Feedback(props: Props) {
	const { onSend, initialComment, initialIsGood, ...modalProps } = props;
	const location = useLocation();
	const [isGood, setIsGood] = React.useState<boolean | undefined>(initialIsGood);
	const [comment, setComment] = React.useState(initialComment || '');

	return (
		<Modal {...modalProps} modalTitle="What did you like or dislike?">
			<Form onSubmit={save}>
				<Stack>
					<ToggleButtonGroup
						value={isGood}
						exclusive
						onChange={(_, value) => setIsGood(value)}
						disabled={initialIsGood != null}
					>
						<ToggleButton value={false}>
							<ThumbDown />
						</ToggleButton>
						<ToggleButton value={true}>
							<ThumbUp />
						</ToggleButton>
					</ToggleButtonGroup>
					<TextField
						label="Comment"
						fullWidth
						multiline
						readOnly={!!initialComment}
						disabled={!!initialComment}
						rows={5}
						value={comment}
						onChange={setComment}
						inputProps={{ maxLength: 1000 }}
					/>
				</Stack>
				<ModalFooter>
					<ButtonGroup>
						<Button type="submit" variant="contained">
							Send
						</Button>
						<Button type="button" onClick={() => props.onClose()}>
							Cancel
						</Button>
					</ButtonGroup>
				</ModalFooter>
			</Form>
		</Modal>
	);

	async function save() {
		FeedbackApi.create({ isGood: isGood ?? null, comment, pathname: location.pathname });
		props.onSend();
	}
}
