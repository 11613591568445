export const seafood = `Sea Urchin
Sea Bass
Halibut
Sole
Tilefish
Monkfish
Arctic Char
Char
Tilapia
Sardines
Crab
Lobster
Shrimp
Catfish
Clams
Mussels
Scallops
Cobia
Crab Legs
Fish Fillets
Oysters
Abalone
Anchovies
Tuna
Black Cod
Barramundi
Eel
Squid
Octopus
Trout
Shark
Escolar
Flounder
Haddock
Crayfish
Croaker
Hake
Herring
MacKerel
Mullet
Perch
Plaice
Pollock
Shad
Freshwater Whitefish
White Fish
Whiting
Carp
Jacksmelt
Mahi Mahi
Skate
Red Snapper
Weakfish
Bluefish
Grouper
Black Bass
Striped Bass
Marlin
Orange Roughy
Swordfish
Bombay Duck
Bream
Turbot
Dogfish
John Dory
Wahoo
Crevalle Jack
Amberjack
Opah
Gemfish
Silver Trevally
Pike
Silver Pomfret
Pompano
Sturgeon
Geoduck
Caviar
Ikura
Tobiko
Crab Meat
Kazunoko
Mentaiko
Shad Roe
Whitefish Roe
Beluga Caviar
Mullet Roe
Bowfin Roe
Cod Roe
Crab Roe
Lobster Roe
Cockles
Conch
Cuttlefish
Sea Cucumber
Jellyfish
Loco
Periwinkle
Turtle
Shirako
Fugu
Whale Meat
Flying Fish
Smelt
Hoya
Sazae
Shirouo No Odorigui
Kamenote
Flathead
Gaebul
Sheepshead
Hogfish
Red Drum
Triggerfish
Sunfish
Giant Clam
Mantis Shrimp
Beltfish
Mudskipper
Parrotfish
Angler Fish
Rabbitfish
Hagfish
Dolphin
Lingcod
Irish Lord
Kibinago
Snakehead
Walleye
Swai
Snook
Limpets
Queen Snapper
Hawaiian Snapper
Hooligan
Bluegill
Gar
Razor Clams
Muskellunge
Peacock Bass
Salmon
Lamprey
Sprats
Krill
Spoonbill
Paddlefish
Whelks
Cod
Coho Salmon
Maine Lobster
King Salmon
Dungeness Crab
Largemouth Bass
Pink Salmon
Rock Lobster
Spot Prawns
Stripped Bass
Yelloweye Rockfish
Tanner
Snow Crab
King Crab
Salmon Cavier
Black Grouper
Crawfish
Goose Barnacles
Smelt Cavier
Atlantic Salmon
Chum Salmon
Blue Crab
Rock Crab
Green Mussles
Smallmouth Bass
Sockeye Salmon
Blowfish
Fugu
Polluck
Stingrays
Bowhead Whales
Sturgeon Cavier
Kokanee`;
