import { units } from './units';
import { Ingredient } from './parse';

export function strigifyIngredient(ingredient: Omit<Ingredient, 'fullIngredient'>) {
	let quantity = '';
	let unit = ingredient.unit;
	if (ingredient.quantity) {
		const [whole, remainder] = ingredient.quantity.toString().split('.');
		if (+whole !== 0 && typeof whole !== 'undefined') {
			quantity = whole;
		}
		if (+remainder !== 0 && typeof remainder !== 'undefined') {
			let fractional;
			if (repeatingFractions[remainder.slice(0, 3)]) {
				fractional = repeatingFractions[remainder.slice(0, 3)];
			} else {
				const fraction = '0.' + remainder;
				const len = fraction.length - 2;
				let denominator = Math.pow(10, len);
				let numerator = +fraction * denominator;

				const divisor = gcd(numerator, denominator);

				numerator /= divisor;
				denominator /= divisor;
				fractional = Math.floor(numerator) + '/' + Math.floor(denominator);
			}

			quantity += quantity ? ' ' + fractional : fractional;
		}
		if (((+whole !== 0 && typeof remainder !== 'undefined') || +whole > 1) && unit) {
			if (units[unit].plural) {
				// unit = nounInflector.pluralize(unit);
			}
		}
	} else {
		return ingredient.name;
	}

	return `${quantity}${unit ? ' ' + unit : ''} ${ingredient.name}`;
}

const repeatingFractions: Record<string, string> = {
	'333': '1/3',
	'666': '2/3',
	'111': '1/9',
	'166': '1/6',
	'833': '5/6',
};

function gcd(a: number, b: number): number {
	if (b < 0.0000001) {
		return a;
	}

	return gcd(b, Math.floor(a % b));
}
