/* eslint-disable no-extend-native */

declare global {
	interface Date {
		isSameDate(input: Date): boolean;
		hasHappened(): boolean;
		addDays(numberOfDays: number): Date;
		getStartOfDay(): Date;
		getPastDayOfWeek(dayIndex: number): Date;
	}
}

Date.prototype.isSameDate = function (input) {
	return (
		this.getDate() === input.getDate() &&
		this.getMonth() === input.getMonth() &&
		this.getFullYear() === input.getFullYear()
	);
};

Date.prototype.hasHappened = function () {
	const today = new Date();
	return !this.isSameDate(today) && this < today;
};

Date.prototype.addDays = function (numberOfDays) {
	const nextDate = new Date(this);
	nextDate.setDate(this.getDate() + numberOfDays);
	return nextDate;
};

Date.prototype.getPastDayOfWeek = function (dayIndex: number) {
	const startDayIndex = this.getDay();
	let diff = dayIndex - startDayIndex;
	if (diff > 0) {
		diff = diff - 7;
	}
	return this.addDays(diff);
};

Date.prototype.getStartOfDay = function () {
	const start = new Date(this);
	start.setHours(0, 0, 0, 0);
	return start;
};

export {};
