import { ModalBody } from 'Components';
import { styled } from '@mui/material';
import shouldForwardProp from '@emotion/is-prop-valid';

export const Body = styled(ModalBody)`
	min-width: 100%;
	min-height: 100%;
	padding: 0;
`;

export const Footer = styled('div')`
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	border-top: 1px solid ${props => props.theme.palette.divider};
	padding: 20px;
	z-index: 1151;
`;

export const StyledCamera = styled('div', { shouldForwardProp })<{
	cameraLoaded: boolean;
}>`
	#container-circles {
		position: fixed;
		bottom: 160px;
		display: ${props => (props.cameraLoaded ? 'block' : 'none')};
	}
`;
