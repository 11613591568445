import * as React from 'react';
import MuiModal, { ModalProps as MuiProps } from '@mui/material/Modal';
import { Box, IconButton, Typography, styled, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface ModalProps extends Omit<MuiProps, 'children'> {
	modalTitle?: React.ReactNode;
	children?: React.ReactNode;
	Body?: React.FC;
	onClose(): void;
}

export const ModalBody = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 600px;
	border: 2px solid #000;
	border-radius: 4px;
	padding: 30px;
	background-color: ${props => props.theme.palette.background.paper};
	${props => props.theme.breakpoints.down('sm')} {
		min-width: 100%;
		padding: 15px;
		height: 100%;
	}
	${props => props.theme.breakpoints.down('sm')} {
		min-width: 100%;
		padding: 15px;
	}
	@media (max-height: 500px) {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		transform: none;
	}
`;

export function Modal(props: ModalProps) {
	const { modalTitle, children, Body, ...muiProps } = props;
	const Content = Body || ModalBody;
	return (
		<MuiModal {...muiProps}>
			<Content>
				{modalTitle && (
					<Stack
						justifyContent="space-between"
						alignItems="flex-start"
						flexDirection="row"
					>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
							sx={{ marginBottom: '20px', marginRight: '20px' }}
						>
							{modalTitle}
						</Typography>
						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={props.onClose}
						>
							<CloseIcon />
						</IconButton>
					</Stack>
				)}
				{children}
			</Content>
		</MuiModal>
	);
}

export const ModalFooter: React.FC<React.HTMLAttributes<any>> = props => (
	<div style={{ marginTop: '20px', ...(props.style as React.CSSProperties) }}>
		{props.children}
	</div>
);
