import { ModalProps, Modal, ModalFooter } from 'Components';
import {
	Button,
	ButtonGroup,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Tooltip,
} from '@mui/material';
import { parseIngredients, Ingredient } from './parseIngredient/parse';
import { useState } from 'react';
import PlaylistAddCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCircleOutlined';
import PlaylistAddCheckCircleOutlinedIcon from '@mui/icons-material/PlaylistAddCheckCircleOutlined';
import { Meal, MealApi } from 'Api';
import { useAppContext } from 'AppContext';

interface Props {
	meal: Meal;
}

export function ChooseIngredient(props: Props) {
	const { recipes } = useAppContext();
	const recipe = recipes?.find(r => r.id === props.meal.recipeId);
	const choiceIngredients = parseIngredients(recipe?.ingredients).filter(i => i.choice);
	const [isChoosing, setIsChoosing] = useState(false);

	if (!choiceIngredients.length) {
		return null;
	}

	return (
		<>
			<Tooltip title={props.meal.choiceIngredient ?? 'Choose ingredient'}>
				<IconButton onClick={() => setIsChoosing(true)} sx={{ marginTop: '-8px' }}>
					{!props.meal.choiceIngredient ? (
						<PlaylistAddCircleOutlinedIcon />
					) : (
						<PlaylistAddCheckCircleOutlinedIcon color="primary" />
					)}
				</IconButton>
			</Tooltip>
			<ChooseIngredientModal
				recipeName={recipe!.name}
				value={props.meal.choiceIngredient}
				options={choiceIngredients}
				saveChoice={saveChoice}
				open={isChoosing}
				onClose={() => setIsChoosing(false)}
			/>
		</>
	);

	async function saveChoice(choiceIngredient: string) {
		setIsChoosing(false);
		await MealApi.update({ ...props.meal, choiceIngredient });
	}
}

interface ChooseIngredientModalProps {
	recipeName: string;
	value: string | undefined;
	options: Ingredient[];
	saveChoice(value: string): void;
}

function ChooseIngredientModal(props: ChooseIngredientModalProps & ModalProps) {
	const { recipeName, value, options, saveChoice, ...modalProps } = props;
	const [choice, setChoice] = useState(value);

	return (
		<Modal {...modalProps} modalTitle={recipeName}>
			<FormControl>
				<FormLabel>Choose ingredient</FormLabel>
				<RadioGroup value={choice} onChange={e => setChoice(e.currentTarget.value)}>
					{options.map(option => (
						<FormControlLabel
							key={option.fullIngredient}
							value={option.fullIngredient}
							control={<Radio />}
							label={option.fullIngredient}
						/>
					))}
				</RadioGroup>
			</FormControl>
			<ModalFooter>
				<ButtonGroup>
					<Button onClick={done} variant="contained">
						Save
					</Button>
					<Button type="button" onClick={() => props.onClose()}>
						Cancel
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</Modal>
	);

	function done() {
		if (!choice) {
			return;
		}
		saveChoice(choice);
	}
}
