import { Loading } from 'Components';
import { Suspense, lazy } from 'react';

const Component = lazy(() =>
	import('./Household').then(({ Household }) => ({ default: Household }))
);

export function Household() {
	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	);
}
