import { styled } from '@mui/material';
import shouldForwardProp from '@emotion/is-prop-valid';

export const StyledDateToolbar = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const StyledDayPicker = styled('div')`
	border: 1px solid ${props => props.theme.palette.divider};
	border-radius: 5px;
	padding: 5px;
`;

export const StyledDays = styled('div')`
	display: flex;
	justify-content: space-between;
`;

export const StyledDateBlock = styled('div', { shouldForwardProp })<{
	selected: boolean;
	faded: boolean;
}>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	text-align: center;
	opacity: ${props => (props.faded ? 0.6 : 1)};
	> div:nth-of-type(1) {
		color: ${props => (props.selected ? props.theme.palette.primary.main : 'inherit')};
	}
	> div:nth-of-type(2) {
		display: flex;
		justify-content: center;
		> div {
			position: relative;
			border-radius: 50%;
			width: 35px;
			height: 35px;
			line-height: 30px;
			color: ${props => (props.selected ? 'white' : 'inherit')};
			background-color: ${props =>
				props.selected ? props.theme.palette.primary.main : 'inherit'};
		}
	}
`;

export const StyledTodayDot = styled('div', { shouldForwardProp })<{
	selected: boolean;
}>`
	position: absolute;
	bottom: 4px;
	width: 35px;
	height: 5px;
	display: flex;
	justify-content: center;
	> div {
		border-radius: 50%;
		width: 5px;
		height: 5px;
		background-color: ${props =>
			props.selected ? 'white' : props.theme.palette.primary.main};
	}
`;
