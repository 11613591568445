import React from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Login } from './Login';
import { useAppContext } from './AppContext';
import { Household } from './Household';
import { Home } from './Home';
import { UserSettingsPage } from './UserSettings';
import { Today } from './Today';
import { Admin } from './Admin';
import { Authorize } from './Authorize';

export function Router() {
	const { userSettings } = useAppContext();
	return (
		<>
			<ScrollToTop />
			<AuthCheck />
			<Routes>
				<Route path="/" element={<HomeRedirect />} />
				<Route path="/login" element={<Login />} />
				<Route path="/authorize" element={<Authorize />} />
				<Route path="/household/*" element={<Household />} />
				<Route path="/home/*" element={<Home />} />
				<Route path="/today/*" element={<Today />} />
				<Route path="/settings" element={<UserSettingsPage />} />
				{userSettings?.entitlements?.admin && <Route path="/admin" element={<Admin />} />}
			</Routes>
		</>
	);
}

function HomeRedirect() {
	const appContext = useAppContext();
	if (!appContext.user) {
		return <Navigate to="/login" />;
	}

	if (!appContext.household) {
		return <Navigate to="/household/create" />;
	}

	return <Navigate to="/home" />;
}

function AuthCheck(): null {
	const appContext = useAppContext();
	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!appContext.user) {
			navigate('/login' + location.search);
		} else if (!appContext.household) {
			navigate('/household/create');
		}
	}, []);

	return null;
}

function ScrollToTop(): null {
	const location = useLocation();
	React.useEffect(() => {
		const anchor = document.querySelector('#back-to-top-anchor');
		if (anchor) {
			anchor.scrollIntoView({
				block: 'center',
			});
		}
	}, [location.pathname]);

	return null;
}
