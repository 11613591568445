import * as React from 'react';
import {
	api,
	Recipe,
	configureUserId,
	Meal,
	Household as HouseholdType,
	SettingsApi,
	PantryItem,
	UserSettings,
	configureHouseholdId,
	RecipeApi,
	MealApi,
	PantryApi,
	allMealTimes,
	demoRecipes,
	NotificationApi,
	Subscription,
} from 'Api';
import { ErrorBoundary, parseIngredient, Toast } from './Components';
import { User } from 'firebase/auth';
import { Button } from '@mui/material';

const Context = React.createContext<AppContextProps>({
	update: () => {},
	addTag: () => {},
	createToast: () => {},
});

interface Props {
	onThemeChange(theme: string | undefined): void;
	children(loading: boolean): React.ReactNode;
}

export interface AppContextProps extends AppContextState {
	update(state: Omit<AppContextState, 'meals' | 'pantry'>): void;
	addTag(tag: string): void;
	createToast(component: React.ReactNode): void;
}

interface AppContextState {
	user?: User;
	userSettings?: UserSettings;
	household?: HouseholdType;
	recipes?: Recipe[];
	meals?: Meal[];
	pantry?: PantryItem[];
	allTags?: Set<string>;
	devicePushSubscription?: PushSubscription;
	registeredPushSubscription?: PushSubscription;
	subscriptions?: Subscription[];
	toast?: React.ReactNode;
}

interface State extends AppContextState {
	loading?: boolean;
}

export class AppContext extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = { loading: true };

		showServiceWorkerToast = sw =>
			this.setState({
				toast: (
					<Toast severity="info">
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<span style={{ marginRight: '5px' }}>New UI available!</span>
							<Button
								color="inherit"
								size="small"
								variant="text"
								sx={{ textDecoration: 'underline' }}
								onClick={() => {
									this.setState({ loading: true, toast: undefined });
									sw.waiting?.postMessage({ type: 'SKIP_WAITING' });
								}}
							>
								Update
							</Button>
						</div>
					</Toast>
				),
			});
	}

	public render() {
		return (
			<Context.Provider
				value={{
					...this.state,
					update: newState => this.setState(newState),
					addTag: this.addTag,
					createToast: toast => this.setState({ toast }),
				}}
			>
				<ErrorBoundary>{this.props.children(!!this.state.loading)}</ErrorBoundary>
				{this.state.toast &&
					React.cloneElement(this.state.toast as any, {
						open: true,
						onClose: () => this.setState({ toast: undefined }),
					})}
			</Context.Provider>
		);
	}

	public componentDidMount() {
		this.getAuthState();
		navigator.serviceWorker.addEventListener('message', event => {
			if (event.data.action === 'ignore' && event.data.data && this.state.userSettings) {
				const ignoredIngredients = (event.data.data as string[]).map(f =>
					parseIngredient(f).name.toLowerCase()
				);
				const thawIgnoreList = new Set([
					...(this.state.userSettings.thawIgnoreList || []),
					...ignoredIngredients,
				]);
				SettingsApi.updateUserSettings({ thawIgnoreList: Array.from(thawIgnoreList) });
			}
		});
	}

	public componentDidUpdate(_prevProps: {}, prevState: State) {
		if (prevState.userSettings?.darkMode !== this.state.userSettings?.darkMode) {
			this.props.onThemeChange(this.state.userSettings?.darkMode);
		}
		if (
			prevState.userSettings?.householdId !== this.state.userSettings?.householdId &&
			this.state.userSettings?.householdId
		) {
			MealApi.listener(meals => this.setState({ meals }));
			PantryApi.listener(pantry => this.setState({ pantry }));
			RecipeApi.listener(recipes => {
				demoRecipes.forEach(demoRecipe => {
					if (!(this.state.household?.completedTutorials as any)?.[demoRecipe.id]) {
						recipes.push(demoRecipe);
					}
				});
				const allTags = new Set<string>(allMealTimes);
				recipes?.forEach(recipe => {
					recipe.tags?.forEach(tag => {
						allTags.add(tag);
					});
				});
				this.setState({ recipes, allTags });
			});
		}
	}

	private getAuthState() {
		api.auth.onAuthStateChanged(async user => {
			if (user) {
				configureUserId(user.uid);
				this.setState({ user });
				const userSettings = await SettingsApi.getUserSettings();
				configureHouseholdId(userSettings?.householdId);
				const household = await SettingsApi.getHousehold().catch(() => undefined);
				this.registerPushSubscription(userSettings);

				this.setState({
					user,
					userSettings,
					household,
					loading: false,
				});
			} else {
				this.setState({ loading: false });
			}
		});
	}

	private addTag = (newTag: string) => {
		const allTags = this.state.allTags?.add(newTag);
		this.setState({ allTags });
	};

	private async registerPushSubscription(userSettings: UserSettings | undefined) {
		if (window.Notification?.permission !== 'granted') {
			return;
		}

		const [devicePushSubscription, registeredPushSubscription, subscriptions] =
			await Promise.all([
				NotificationApi.getDevice().catch(() => undefined),
				NotificationApi.getRegisteredDevice().catch(() => undefined),
				NotificationApi.get().catch(() => []),
			]);

		if (
			!subscriptions.length ||
			!devicePushSubscription ||
			devicePushSubscription.endpoint === registeredPushSubscription?.endpoint
		) {
			this.setState({
				devicePushSubscription,
				registeredPushSubscription,
				subscriptions,
			});
			return;
		}

		const newRegistration = await NotificationApi.registerDevice(devicePushSubscription);
		this.setState({
			devicePushSubscription,
			registeredPushSubscription: newRegistration,
			subscriptions,
		});
	}
}

export function withAppContext<TProps>(Component: React.ComponentType<TProps>) {
	const WithContext: React.FC<TProps> = props => {
		return (
			<Context.Consumer>
				{context => <Component {...props} {...context} />}
			</Context.Consumer>
		);
	};
	return WithContext as unknown as React.FC<Omit<TProps, keyof AppContextProps>>;
}

export const useAppContext = () => React.useContext(Context);

export let showServiceWorkerToast = (sw: ServiceWorkerRegistration) => {
	console.log('app context not ready');
};
