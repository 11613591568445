import { api, db } from './firebase';
import { Household } from './SettingsApi';
import { addId, encodeEmail } from './util';

export type PendingInvite = {
	id: string;
	householdId: string;
	name: string;
};

async function create(email: string, household: Household | undefined) {
	if (!email || !household) {
		return;
	}
	const pendingRef = db.ref(api.db, `pendingInvites/${encodeEmail(email)}`);
	const pendingInvite: Omit<PendingInvite, 'id'> = {
		householdId: household.id,
		name: household.name,
	};
	await db.set(pendingRef, pendingInvite);
}

async function get(email: string | null | undefined): Promise<PendingInvite | false> {
	if (!email) {
		return false;
	}
	return db
		.get(db.child(db.ref(api.db), `pendingInvites/${encodeEmail(email)}`))
		.then(snapshot => {
			if (snapshot.exists()) {
				return addId(email, snapshot.val());
			}
			return false;
		})
		.catch(() => false);
}

async function remove(email: string | null | undefined) {
	if (!email) {
		return;
	}
	const dbRef = db.ref(api.db, `pendingInvites/${encodeEmail(email)}`);
	await db.remove(dbRef);
}

export const InviteApi = {
	create,
	get,
	remove,
};
