import React from 'react';
import { findDOMNode } from 'react-dom';

export function useInView(target: React.MutableRefObject<any>) {
	const [, forceUpdate] = React.useState();
	const observer = React.useRef<IntersectionObserver>();
	const inViewportRef = React.useRef<boolean>(false);
	const intersected = React.useRef<boolean>(false);

	React.useEffect(() => {
		let observerRef = observer.current;
		observerRef = initIntersectionObserver({ observerRef });

		startObserver({
			observerRef,
		});

		return () => {
			stopObserver({
				observerRef,
			});
		};
	}, [target]);

	function startObserver({ observerRef }: any) {
		const targetRef = target.current;
		if (targetRef) {
			const node = findDOMNode(targetRef);
			if (node) {
				observerRef?.observe(node);
			}
		}
	}

	function stopObserver({ observerRef }: any) {
		const targetRef = target.current;
		if (targetRef) {
			const node = findDOMNode(targetRef);
			if (node) {
				observerRef?.unobserve(node);
			}
		}

		observerRef?.disconnect();
		observer.current = undefined;
	}

	function handleIntersection(entries: any) {
		const entry = entries[0] || {};
		const { isIntersecting, intersectionRatio } = entry;
		const isInViewport =
			typeof isIntersecting !== 'undefined' ? isIntersecting : intersectionRatio > 0;

		if (!intersected.current && isInViewport) {
			intersected.current = true;
			inViewportRef.current = isInViewport;
			forceUpdate(isInViewport);
			return;
		}
	}

	function initIntersectionObserver({ observerRef }: any) {
		if (!observerRef) {
			observer.current = new IntersectionObserver(handleIntersection);
			return observer.current;
		}
		return observerRef;
	}

	return inViewportRef.current;
}
