import React from 'react';
import { Form } from '../Form';
import { MealTime, MealApi, Meal } from 'Api';
import { Modal, ModalProps, ModalFooter } from '../Modal';
import { Button, ButtonGroup, FormLabel } from '@mui/material';
import { DatePicker } from './DatePicker';
import { useAppContext } from 'AppContext';
import { RecipeTypeahead } from '../RecipeTypeahead';
import { MealTimeDropdown } from '../MealTimeDropdown';

interface EditMealProps {
	meal?: Meal;
}

export function EditMeal(props: EditMealProps & ModalProps) {
	const { meals, recipes, pantry } = useAppContext();
	const { meal, children, ...modalProps } = props;

	const recipe = recipes?.find(r => r.id === meal?.recipeId);
	const [date, setDate] = React.useState<Date>(meal?.date ?? new Date());
	const [mealTime, setMealTime] = React.useState<MealTime | undefined | null>(
		meal?.mealTime
	);

	return (
		<Modal {...modalProps} modalTitle={'Move Meal'}>
			<Form onSubmit={saveMeal}>
				<RecipeTypeahead
					label="Recipe"
					mealTime={mealTime}
					defaultValue={recipe}
					readOnly
					disabled
				/>
				<FormLabel>Time</FormLabel>
				<div>
					<MealTimeDropdown mealTime={mealTime} setMealTime={setMealTime} />
				</div>
				<DatePicker
					date={date}
					onChange={setDate}
					mealTime={mealTime}
					mealId={meal?.id}
				/>
				<ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
					<ButtonGroup>
						<Button type="submit" variant="contained">
							Save
						</Button>
						<Button type="button" onClick={() => props.onClose()}>
							Cancel
						</Button>
					</ButtonGroup>
					<Button type="button" variant="outlined" onClick={remove}>
						Delete
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);

	async function saveMeal() {
		if (!meal) {
			return;
		}

		await MealApi.update({
			...meal,
			date,
			mealTime: mealTime || null,
		});
		props.onClose();
	}

	async function remove() {
		if (!meal) {
			return;
		}

		await MealApi.remove(meal, meals, recipe, pantry);
		props.onClose();
	}
}
