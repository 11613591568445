import { api, db } from './firebase';
import { addId, removeId } from './util';

export interface UserSettings {
	householdId?: string;
	darkMode?: 'light' | 'dark' | 'device';
	completedTutorials?: Partial<Record<UserTutorials, boolean>>;
	entitlements?: Partial<Record<Entitlements, boolean>>;
	accessTokens?: Record<string, string>;
	defaultMealPlanView?: MealPlanView;
	mealPlanTableColumns?: string[];
	startDayOfWeek?: number;
	thawIgnoreList?: string[];
}

export enum MealPlanView {
	list = 'list',
	table = 'table',
}

export type UserTutorials =
	| 'grocery-list-pending-meals'
	| 'mealplan-add-first-meal'
	| 'grocery-list-create-aisle';

export type Entitlements = 'admin' | 'accessTokens';

export interface Household {
	id: string;
	name: string;
	ownerId: string;
	write?: Record<string, boolean | null>;
	numberOfMembers?: number | null;
	completedTutorials?: Partial<Record<HouseholdTutorials, boolean>>;
}

export type HouseholdTutorials =
	| 'demo-recipe-breakfast'
	| 'demo-recipe-lunch'
	| 'demo-recipe-dinner'
	| 'demo-recipe-other';

async function getUserSettings(): Promise<UserSettings | undefined> {
	if (!api.userId) {
		return undefined;
	}
	const snapshot = await db.get(db.child(db.ref(api.db), `users/${api.userId}`));
	if (snapshot.exists()) {
		return snapshot.val();
	}
	return {};
}

async function updateUserSettings(value: UserSettings) {
	if (!api.userId) {
		return;
	}
	const userRef = db.ref(api.db, `users/${api.userId}`);
	await db.update(userRef, value);
}

async function removeUser() {
	if (!api.userId) {
		return;
	}

	const ref = db.ref(api.db, `users/${api.userId}`);
	await db.remove(ref);
}

async function createHousehold(value: string): Promise<Household> {
	const household: Omit<Household, 'id'> = { name: value, ownerId: api.userId };
	const dbRef = db.ref(api.db, 'households');
	const newRef = db.push(dbRef);
	await db.set(newRef, household);

	await updateUserSettings({ householdId: newRef.key! });
	return { id: newRef.key!, ...household };
}

async function getHousehold(): Promise<Household | undefined> {
	if (!api.householdId) {
		return undefined;
	}
	const snapshot = await db.get(
		db.child(db.ref(api.db), `households/${api.householdId}`)
	);
	if (snapshot.exists()) {
		return addId(api.householdId, snapshot.val());
	}
	return undefined;
}

async function updateHousehold(value: Household) {
	const newValue = { ...value };
	if (!newValue.id) {
		return;
	}

	const id = removeId(newValue);
	const householdRef = db.ref(api.db, `households/${id}`);
	await db.update(householdRef, newValue);
}

async function removeHousehold(householdId: string | undefined) {
	if (!householdId) {
		return;
	}

	const recipesRef = db.ref(api.db, `recipes/${householdId}`);
	await db.remove(recipesRef);
	const mealsRef = db.ref(api.db, `meals/${householdId}`);
	await db.remove(mealsRef);
	const groceriesRef = db.ref(api.db, `groceries/${householdId}`);
	await db.remove(groceriesRef);
	const pantryRef = db.ref(api.db, `pantry/${householdId}`);
	await db.remove(pantryRef);
	const householdRef = db.ref(api.db, `households/${householdId}`);
	await db.remove(householdRef);
}

function isUserTutorialDone(
	userSettings: UserSettings | undefined,
	tutorial: UserTutorials
) {
	return !!userSettings?.completedTutorials?.[tutorial];
}

export function updateHouseholdTutorials(household: Household, demoId: string) {
	const newHousehold: Household = {
		...household,
		completedTutorials: {
			...household.completedTutorials,
			[demoId]: true,
		},
	};
	SettingsApi.updateHousehold(newHousehold);
	return newHousehold;
}

export const SettingsApi = {
	getUserSettings,
	updateUserSettings,
	removeUser,
	createHousehold,
	getHousehold,
	updateHousehold,
	removeHousehold,
	isUserTutorialDone,
	updateHouseholdTutorials,
};
