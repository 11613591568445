import { AppBar, AppBarProps, styled, useTheme } from '@mui/material';
import { createContext, FC, forwardRef, useContext, useEffect, useState } from 'react';
import { useIsSmallScreen } from './useIsSmallScreen';

interface AppBarOffset {
	bars: Record<string, number>;
	setbars: (entry: Record<string, number>) => void;
	getOffset: (name?: string) => number;
}

export const AppBarContext = createContext<{
	top: AppBarOffset;
	bottom: AppBarOffset;
}>({
	top: { bars: {}, setbars: () => {}, getOffset: () => 0 },
	bottom: { bars: {}, setbars: () => {}, getOffset: () => 0 },
});

export const useAppBarContext = () => useContext(AppBarContext);

export const AppBarProvider: FC = props => {
	const [topBars, setTopBars] = useState<Record<string, number>>({});
	const [bottomBars, setBottomBars] = useState<Record<string, number>>({});

	return (
		<AppBarContext.Provider
			value={{
				top: {
					bars: topBars,
					setbars: setTopBars,
					getOffset: name => getOffset(topBars, name),
				},
				bottom: {
					bars: bottomBars,
					setbars: setBottomBars,
					getOffset: name => getOffset(bottomBars, name),
				},
			}}
		>
			{props.children}
		</AppBarContext.Provider>
	);

	function getOffset(bars: Record<string, number>, name?: string) {
		const entries = Object.keys(bars);
		const end = name ? entries.indexOf(name) : undefined;
		const offset = entries.slice(0, end).reduce((value, entry) => {
			if (entry !== name) {
				return value + bars[entry];
			}
			return value;
		}, 0);
		return offset;
	}
};

interface BarProps extends AppBarProps {
	height: number;
	name: string;
	main?: boolean;
	skipOffset?: boolean;
}

export const TopAppBar = forwardRef<any, BarProps>(
	({ height, name, skipOffset, ...props }, ref) => {
		const { top } = useAppBarContext();
		const smallScreen = useIsSmallScreen();

		useEffect(() => {
			top.setbars({ ...top.bars, [name]: height });
			return () => {
				const newRegistry = { ...top.bars };
				delete newRegistry[name];
				top.setbars(newRegistry);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [height]);

		if (!top.bars[name]) {
			return null;
		}

		return (
			<>
				<AppBar
					{...props}
					sx={{
						width: smallScreen ? '100%' : 'calc(100% - 250px)',
						height: `${height}px`,
						top: top.getOffset(name),
						...props.sx,
					}}
					ref={ref}
				>
					{props.children}
				</AppBar>
				{!skipOffset && <div style={{ height }} />}
			</>
		);
	}
);

export const mobileSafePadding = 'env(safe-area-inset-bottom, 0px)';

const StyledBottomAppBar = styled(AppBar)<{ first?: boolean }>`
	position: fixed;
	top: auto;
	background-color: ${props => props.theme.palette.background.default};
`;

export const BottomAppBar: React.FC<BarProps> = ({ height, name, main, ...props }) => {
	const { bottom } = useAppBarContext();
	const smallScreen = useIsSmallScreen();
	const theme = useTheme();

	useEffect(() => {
		if (main) {
			bottom.setbars({ [name]: height, ...bottom.bars });
		} else {
			bottom.setbars({ ...bottom.bars, [name]: height });
		}
		return () => {
			const newRegistry = { ...bottom.bars };
			delete newRegistry[name];
			bottom.setbars(newRegistry);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isFirst = Object.keys(bottom.bars)[0] === name;
	const adjustedBottom = `calc(${bottom.getOffset(name)}px + ${mobileSafePadding})`;
	const adjustedHeight = `calc(${height}px + ${mobileSafePadding})`;

	return (
		<>
			<div style={{ height: isFirst ? adjustedHeight : height }} />
			<StyledBottomAppBar
				{...props}
				sx={{
					width: smallScreen ? '100%' : 'calc(100% - 250px)',
					bottom: isFirst ? bottom.getOffset(name) : adjustedBottom,
					height: isFirst ? adjustedHeight : height,
					paddingBottom: isFirst ? mobileSafePadding : '0',
					borderTop: main ? `2px solid ${theme.palette.divider}` : '',
				}}
			>
				{props.children}
			</StyledBottomAppBar>
		</>
	);
};
