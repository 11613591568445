import React from 'react';

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
	onSubmit?(event: React.FormEvent<HTMLFormElement>): void;
}

export function Form({ ...formProps }: FormProps) {
	return (
		<form
			{...formProps}
			onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				formProps.onSubmit && formProps.onSubmit(e);
			}}
		/>
	);
}
