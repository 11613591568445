import { Button } from '@mui/material';
import { AppContextProps, useAppContext } from 'AppContext';
import { Feedback } from 'Masthead/Feedback';
import React from 'react';
import { useLocation, Location } from 'react-router-dom';
import { Toast } from './Toast';

interface Props extends AppContextProps {
	location: Location;
}

interface State {
	error?: Error;
	showFeedback?: boolean;
}

class ErrorBoundary2 extends React.Component<Props, State> {
	public state: State = { showFeedback: false };

	public render() {
		const { error } = this.state;
		if (error) {
			return (
				<div style={{ margin: '20px' }}>
					<div>Something went wrong!</div>
					<pre
						style={{ marginLeft: '20px', overflow: 'auto' }}
					>{`${error.name}: ${error.message}`}</pre>
					<Button onClick={() => this.setState({ showFeedback: true })}>
						Report Error
					</Button>
					<Feedback
						key={this.state.showFeedback ? 'true' : 'false'}
						open={!!this.state.showFeedback}
						initialComment={
							`${error.name}: ${error.message}\n` + JSON.stringify(error.stack, null, 2)
						}
						initialIsGood={false}
						onClose={() => this.setState({ showFeedback: false })}
						onSend={() => {
							this.props.createToast(
								<Toast autoHideDuration={5000} severity="success">
									Thanks for the feedback!
								</Toast>
							);
							this.setState({ showFeedback: false });
						}}
					/>
				</div>
			);
		}

		return <>{this.props.children}</>;
	}

	public componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({ error: undefined });
		}
	}

	public componentDidCatch(error: Error) {
		this.setState({ error });
	}
}

export const ErrorBoundary: React.FC = props => {
	const appContext = useAppContext();
	const location = useLocation();
	return <ErrorBoundary2 {...props} {...appContext} location={location} />;
};
