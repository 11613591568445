import * as React from 'react';
import { DropdownMenu, Toast, isIframed } from 'Components';
import {
	Avatar,
	Box,
	ListItemIcon,
	Button,
	Tooltip,
	IconButton,
	Typography,
	Toolbar,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { Cast, Feedback as FeedbackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'AppContext';
import { api } from 'Api';
import { Feedback } from './Feedback';
import { NavigationDrawer } from './NavigationDrawer';
import { TopAppBar, useIsSmallScreen } from 'Components';
import { AboutReciplanModal } from './AboutReciplanModal';
import { TodayMasthead } from 'Today/Masthead';

export const Masthead = React.forwardRef((_, ref) => {
	const { user, createToast, userSettings } = useAppContext();
	const navigate = useNavigate();
	const smallScreen = useIsSmallScreen();
	const [showFeedback, setShowFeedback] = React.useState(false);
	const [showAbout, setShowAbout] = React.useState(false);
	const [openNav, setOpenNav] = React.useState(false);

	return (
		<>
			<TopAppBar
				enableColorOnDark
				name="masthead"
				height={smallScreen ? 56 : 64}
				skipOffset
				sx={{ top: 0, width: '100%', zIndex: theme => theme.zIndex.drawer + 1 }}
				ref={ref}
			>
				{!isIframed() && (
					<Toolbar>
						<Button variant="text" color="inherit" onClick={() => navigate('/')}>
							<Typography variant="h6" noWrap component="div">
								Reciplan
							</Typography>
						</Button>
						<Typography fontSize="small">BETA</Typography>
						<Box sx={{ flexGrow: 1 }} />
						<Box sx={{ flexGrow: 0 }}>
							{!isIframed() && (
								<IconButton onClick={cast}>
									<Cast />
								</IconButton>
							)}
							{user && (
								<Tooltip title="Give feedback">
									<IconButton onClick={() => setShowFeedback(true)}>
										<FeedbackIcon sx={{ color: '#fff' }} />
									</IconButton>
								</Tooltip>
							)}
							{user && (
								<DropdownMenu
									anchorVertical="bottom"
									options={[
										{
											children: (
												<>
													<ListItemIcon>
														<SettingsIcon fontSize="small" />
													</ListItemIcon>
													User Settings
												</>
											),
											onClick: () => navigate('/settings'),
										},
										{
											children: (
												<>
													<ListItemIcon>
														<HomeIcon fontSize="small" />
													</ListItemIcon>
													Household
												</>
											),
											onClick: () => navigate('/household'),
										},
										userSettings?.entitlements?.admin && {
											children: (
												<>
													<ListItemIcon>
														<SettingsIcon fontSize="small" />
													</ListItemIcon>
													Admin
												</>
											),
											onClick: () => navigate('/admin'),
										},
										{
											children: (
												<>
													<ListItemIcon>
														<LogoutIcon fontSize="small" />
													</ListItemIcon>
													Logout
												</>
											),
											onClick: async () => {
												await api.auth.signOut();
												window.location.assign('/');
											},
										},
										{
											children: 'About Reciplan',
											onClick: () => setShowAbout(true),
										},
									]}
								>
									{dProps => (
										<Tooltip title={user?.displayName || ''}>
											<IconButton onClick={dProps.onClick}>
												<Avatar alt={user?.displayName!} src={user?.photoURL!} />
											</IconButton>
										</Tooltip>
									)}
								</DropdownMenu>
							)}
						</Box>
						<Feedback
							key={showFeedback ? 'true' : 'false'}
							open={showFeedback}
							onClose={() => setShowFeedback(false)}
							onSend={() => {
								createToast(
									<Toast autoHideDuration={5000} severity="success">
										Thanks for the feedback!
									</Toast>
								);
								setShowFeedback(false);
							}}
						/>
					</Toolbar>
				)}
				{isIframed() && <TodayMasthead />}
			</TopAppBar>
			<AboutReciplanModal open={showAbout} onClose={() => setShowAbout(false)} />
			{!smallScreen && !isIframed() && (
				<NavigationDrawer open={openNav} setOpen={setOpenNav} />
			)}
		</>
	);

	async function cast() {
		fetch(`${process.env.REACT_APP_HA_URL}/api/services/script/turn_on`, {
			method: 'post',
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_HA_TOKEN}`,
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				entity_id: 'script.cooking_instructions',
			}),
		});
	}
});
