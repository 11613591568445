import { PaletteOptions } from '@mui/material';

export const reciplanTheme: PaletteOptions = {
	primary: {
		main: '#ef6c00',
		light: '#ff9d3f',
		dark: '#b53d00',
		contrastText: '#ffffff',
	},
};
