import { api, db } from './firebase';
import { removeId, toArray } from './util';

export interface GroceryListItem {
	id: string;
	ingredient: string;
	recipeId?: string | null;
	mealName?: string;
	checked?: boolean;
}

const householdPath = () => `groceries/${api.householdId}`;

function listener(callback: (result: GroceryListItem[]) => void) {
	db.onValue(db.ref(api.db, householdPath()), async snapshot => {
		if (!snapshot.exists()) {
			callback([]);
			return;
		}

		const data = snapshot.val();
		callback(toArray(data));
	});
}

async function create(
	...values: Omit<GroceryListItem, 'id'>[]
): Promise<GroceryListItem[]> {
	const objects = values.reduce((children, value) => {
		const newRef = db.push(db.ref(api.db, householdPath()));
		children[newRef.key!] = value;
		return children;
	}, {} as Record<string, Omit<GroceryListItem, 'id'>>);
	await db.update(db.ref(api.db, householdPath()), objects);
	return toArray(objects);
}

async function get(): Promise<GroceryListItem[]> {
	const snapshot = await db.get(db.child(db.ref(api.db), householdPath()));
	if (snapshot.exists()) {
		return toArray(snapshot.val());
	}
	return [];
}

async function update(...values: GroceryListItem[]) {
	const objects = values.reduce((children, value) => {
		const newValue = { ...value };
		const id = removeId(newValue);
		children[id] = newValue;
		return children;
	}, {} as Record<string, GroceryListItem>);
	const dbRef = db.ref(api.db, householdPath());
	await db.update(dbRef, objects);
}

async function remove(...values: GroceryListItem[]) {
	const objects = values.reduce((children, value) => {
		children[value.id] = null;
		return children;
	}, {} as Record<string, null>);
	const dbRef = db.ref(api.db, householdPath());
	await db.update(dbRef, objects);
}

export const GroceriesApi = {
	listener,
	create,
	get,
	update,
	remove,
};
