import { DropdownMenu } from './DropdownMenu';
import { MealTime as MealTimeEnum, allMealTimes } from '../Api';
import { Chip } from '@mui/material';

interface MealTimeProps extends MealTimeChipProps {
	mealTime?: MealTimeEnum | null;
	setMealTime(mealTag: MealTimeEnum | null): void;
}

export function MealTimeDropdown(props: MealTimeProps) {
	return (
		<DropdownMenu
			anchorHorizontal="left"
			anchorVertical="bottom"
			options={[
				{
					children: 'NONE',
					onClick: () => props.setMealTime!(null),
				},
				...Array.from(allMealTimes).map(t => ({
					children: t.toUpperCase(),
					onClick: () => props.setMealTime!(t),
				})),
			]}
		>
			{buttonProps => (
				<span
					{...buttonProps}
					style={{ marginRight: '8px', cursor: 'pointer', visibility: 'initial' }}
				>
					<MealTimeChip {...props} label={props.mealTime ?? 'None'} />
				</span>
			)}
		</DropdownMenu>
	);
}

interface MealTimeChipProps {
	label?: string | null;
	size?: 'small' | 'medium';
	sx?: any;
}

export function MealTimeChip(props: MealTimeChipProps) {
	if (!props.label) {
		return null;
	}

	return (
		<Chip
			label={props.label.toUpperCase()}
			variant={'outlined'}
			size={props.size}
			sx={props.sx}
		/>
	);
}
