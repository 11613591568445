import Axios from 'axios';
import { api } from './firebase';
import { Recipe } from './RecipeApi';

const axiosInstance = Axios.create({
	baseURL: true ? 'https://reciplan.chicholls.com/api' : 'http://localhost:3600/api',
});

async function createCode(client_id: string, redirect_uri: string) {
	const idToken = await api.auth.currentUser?.getIdToken();
	return axiosInstance
		.post<string>(
			'/oauth/code',
			{ client_id, redirect_uri },
			{ headers: { token: idToken! } }
		)
		.then(res => {
			return res.data;
		});
}

async function createToken() {
	const idToken = await api.auth.currentUser?.getIdToken();
	return axiosInstance
		.post<{ token: string }>('/auth/createtoken', null, { headers: { token: idToken! } })
		.then(res => {
			return res.data;
		});
}

async function exchangeToken(accessToken: string) {
	return axiosInstance
		.post<{ token: string }>('/auth/exchangetoken', { accessToken, client_id: 'custom' })
		.then(res => {
			return res.data;
		});
}

async function parseRecipe(sourceUrl: string) {
	const idToken = await api.auth.currentUser?.getIdToken();
	return axiosInstance
		.post<Recipe>('/parserecipe', { sourceUrl }, { headers: { token: idToken! } })
		.then(res => {
			return res.data;
		});
}

async function parseImage(image: string, dpi: number, psm: number) {
	const idToken = await api.auth.currentUser?.getIdToken();
	return axiosInstance
		.post<string>('/parseimage', { image, dpi, psm }, { headers: { token: idToken! } })
		.then(res => {
			return res.data;
		});
}

export const BackendApi = {
	createCode,
	createToken,
	exchangeToken,
	parseRecipe,
	parseImage,
};
