import { Recipe, RecipeApi } from './RecipeApi';
import { addId, removeId } from './util';
import { api, db } from './firebase';
import { PantryApi, PantryItem } from './PantryApi';

export interface Meal {
	id: string;
	date: Date;
	name: string;
	mealTime: MealTime | null;
	recipeId?: string | null;
	addedToGroceryList?: boolean;
	parentMealId?: string;
	choiceIngredient?: string;
	isPrep?: boolean;
	isLeftovers?: boolean;
}

export enum MealTime {
	breakfast = 'breakfast',
	lunch = 'lunch',
	dinner = 'dinner',
	dessert = 'dessert',
}

export const allMealTimes = new Set([
	MealTime.breakfast,
	MealTime.lunch,
	MealTime.dinner,
	MealTime.dessert,
]);

const householdPath = () => `meals/${api.householdId}`;

function listener(callback: (result: Meal[]) => void) {
	const ref = db.query(
		db.ref(api.db, householdPath()),
		db.orderByChild('date'),
		db.limitToLast(500)
	);
	db.onValue(ref, async snapshot => {
		const results: Meal[] = [];
		snapshot.forEach(child => {
			const result = child.val();
			results.push(addId(child.key!, { ...result, date: getDateFromKey(result.date) }));
		});
		callback(results);
	});
}

async function create(
	value: Omit<Meal, 'id'>,
	recipe: Recipe | undefined,
	pantry: PantryItem[] | undefined
): Promise<Meal> {
	const newValue = { ...value, date: getDateKey(value.date) };
	const newRef = db.push(db.ref(api.db, householdPath()));
	await db.set(newRef, newValue);

	if (recipe?.id) {
		await RecipeApi.updateUses(recipe.id, 1);
		if (pantry) {
			await PantryApi.updateUses(recipe, pantry, 1);
		}
	}

	return { id: newRef.key!, ...value };
}

async function get(): Promise<Meal[]> {
	const ref = db.query(
		db.ref(api.db, householdPath()),
		db.orderByChild('date'),
		db.limitToLast(500)
	);
	const res = await db.get(ref);

	const results: Meal[] = [];
	res.forEach(child => {
		const result = child.val();
		results.push(addId(child.key!, { ...result, date: getDateFromKey(result.date) }));
	});
	return results;
}

async function update(...values: Meal[]) {
	const objects = values.reduce((children, value) => {
		const newValue = { ...value, date: getDateKey(value.date) };
		const id = removeId(newValue);
		children[id] = newValue;
		return children;
	}, {} as Record<string, Meal>);
	const dbRef = db.ref(api.db, householdPath());
	await db.update(dbRef, objects);
}

async function remove(
	value: Meal,
	meals: Meal[] | undefined,
	recipe: Recipe | undefined,
	pantry: PantryItem[] | undefined
) {
	if (!value.id) {
		return;
	}

	const objects = (meals || [])
		?.filter(m => m.id === value.id || m.parentMealId === value.id)
		.reduce((children, value) => {
			children[value.id] = null;
			return children;
		}, {} as Record<string, null>);
	const dbRef = db.ref(api.db, householdPath());
	await db.update(dbRef, objects);

	if (recipe?.id) {
		await RecipeApi.updateUses(recipe.id, -1);
		if (pantry) {
			await PantryApi.updateUses(recipe, pantry, -1);
		}
	}
}

function getDateKey(date: Date) {
	const year = date.toLocaleDateString(undefined, { year: 'numeric' });
	const month = date.toLocaleDateString(undefined, { month: '2-digit' });
	const day = date.toLocaleDateString(undefined, { day: '2-digit' });
	return `${year}-${month}-${day}` as unknown as Date;
}

function getDateFromKey(dateKey: string) {
	const [year, month, day] = dateKey.split('-');
	return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

export const MealApi = { listener, create, get, update, remove };
