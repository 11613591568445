export function padStart(value: number, digits: number) {
	if (value == null) {
		return '';
	}
	const valueString = value.toString();
	let padString = '0';
	if (valueString.length >= digits) {
		return valueString;
	} else {
		digits = digits - valueString.length;
		if (digits > padString.length) {
			padString += padString.repeat(digits / padString.length);
		}
		return padString.slice(0, digits) + valueString;
	}
}
