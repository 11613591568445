import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface Props extends Omit<CheckboxProps, 'onChange'> {
	onChange?(value: boolean, e: React.ChangeEvent): void;
	children?: any;
	formSx?: any;
}

export function Checkbox(props: Props) {
	const { onChange, children, formSx, ...muiProps } = props;
	return (
		<FormControlLabel
			control={
				<MuiCheckbox
					{...muiProps}
					onChange={onChange ? e => onChange(e.target.checked, e) : undefined}
				/>
			}
			label={children}
			sx={formSx}
		/>
	);
}
