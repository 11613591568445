import { Meal } from 'Api';

export function getMealDisplayName(meal: Meal) {
	if (meal.isLeftovers) {
		return `${meal.name} (leftovers)`;
	}
	if (meal.isPrep) {
		return `${meal.name} (prep)`;
	}
	return meal.name;
}
